import { RepositoryFactory } from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("trulioo")
import { defineStore } from "pinia"
import { useUsersStore } from "./users"

export const useTrulioosStore = defineStore('trulioo', {
  state: () => ({
    loading: false,
    result: [],
    transactionId: '',
    shortCode: '',
    truliooStatus: null,
    formUrl: null,
    shortCodeUsed: false,
    verified: false,
    userId: ''
  }),
  actions: {
    async getFormUrl() {
      try {
        const response = await repository.getFormUrl()
        this.formUrl = response.data.url
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },

    async result(experienceTransactionId) {
      // this.loading = true
      const response = await repository.result(experienceTransactionId)

      this.result = response.data
      this.loading = false

      return response.data
    },

    async getShortCode() {
      this.loading = true
      const response = await repository.getShortCode()

      if (response.data) {
        this.transactionId = response.data.transactionId
        this.shortCode = response.data.shortCode
      }
      this.loading = false
    },
    async sendStatus(payload) {
      try {
        await repository.sendStatus(payload)
      } catch (error) {
        console.error(error)
      }
    },
    async checkShortCode(payload) {
      try {

        const response = await repository.checkShortCode(payload)

        if (response.data && response.data.status && response.data.status === 'success') {
          this.shortCodeUsed = true
        }
      } catch (error) {
        console.error(error)
      }
    },

    setVerificationStatus(payload){
      if(payload.status === 'ACCEPTED'){
        this.truliooStatus = 'ACCEPTED'
        useUsersStore().fetch()
      } else if (payload.status === 'REJECTED') {
        this.truliooStatus = 'REJECTED'
      }
      this.userId = Number(payload.userId)
    }
  },
})
