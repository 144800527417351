import { RepositoryFactory } from '@/api/repositoryFactory'
import i18n from '@/js/language/'
import router from '@/js/router'

import auth from '@/js/helpers/auth'
import mentions from '@/js/helpers/mentions'
import { differenceInSeconds } from 'date-fns'
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useAppsStore } from './app'
import { usePressReleasesStore } from './pressreleases'
import { useOwnersStore } from './owners'
import { useNotificationsStore } from './notifications'
import { useMembersStore } from './members'
import { useMediasStore } from './medias'
import { useInsidersStore } from './insiders'
import { useDocumentsStore } from './documents'
import { useContactsStore } from './contacts'
import { useCompaniesStore } from './companies'
import { useCommentsStore } from './comments'
import { useChatsStore } from './chats'
import { useCalendarEventsStore } from './calendarevents'
import { useBoardMembersStore } from './boardmembers'
import { useAssetsStore } from './assets'
import { usePostsStore } from './posts'
// import { useTriggersStore } from "./triggers"
import { useSocketsStore } from './sockets'
import { useJobsStore } from './jobs'
import { useDamStore } from './dam'

// import { cfaSignIn } from 'capacitor-firebase-auth'
const repository = RepositoryFactory.get('users')

export const useUsersStore = defineStore('users', {
  state: () => ({
    emojiList: [':+1:', ':heart:', ':joy:', ':smile:', ':pray:'],
    needToVerifyTerms: false,
    selectedUserId: null,
    dsecret: null,
    showBecomePremium: false,
    user: {},
    profile: {},
    roles: [],
    followings: {},
    followers: {},
    userList: [],
    filteredUserList: [],
    otherUser: {},
    saved: [],
    selectedOtherUserId: null,
    isRegistered: false,
    alreadyRegistered: false,
    isUpdated: false,
    isLoggedin: false,
    loadingRegistration: false,
    loadingBlock: false,
    loadingUpdate: false,
    loadingUsers: false,
    loadingBubble: false,
    loadingLogin: false,
    loadingOTPCheck: false,
    loadingProfileImage: false,
    errorRegistered: false,
    isVerified: true,
    loginError: '',
    accessToken: '',
    loading: false,
    showVerificationModal: false,
    multiUsers: [],
    loadingFollowings: false,
    loadingFollowers: false,
    savedLoading: false,
    isFollowed: false,
    maybeLater: false,
    isBlocked: false,
    nextPageUrl: null,
    loadingNew: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userMenuReference: null,
    successUrl: 'marketplace',
    create: false,
    title: '',
    body: '',
    locked_at: null,
    locked_to: null,
    userBlocked: false,
    emailSent: false,
    secondaryEmailSent: false,
    setNewPassword: false,
    menu: [
      {
        slide: 0,
        name: 'userFlow',
        label: 'Flow'
      }
      // { slide: 1, name: 'userMedia', label: 'Media' },
      // { slide: 2, name: 'userCompany', label: 'Company' }
    ],
    FCMToken: null,
    idleStatus: false,
    idleMinutes: 5,
    showOtpVerificationModal: false,
    editUserError: '',
    otpConfirmationError: '',
    activeTheme: 'light',
    usersCompany: [],
    originalCurrencies: [],
    countries: [],
    isdCodes: [],
    loadingCurrency: false,
    loadingSecondaryEmail: false,
    loadingStripeCheckout: false,
    isCompanyPremium: false,
    investorPremium: null,
    userFollowings: [],
    userTradingAccountDetails: [],
    poiError: '',
    poaError: '',
    companyReports: [],
    loadingCompanyReports: false,
    companyReportsNextPageUrl: false,
    loadingNewCompanyReports: false,
    loadingSubmitPoi: false,
    loadingSubmitPoa: false,
    infoLoading: false,
    passwordLoading: false
  }),
  getters: {
    showVerify(state) {
      if (state.maybeLater) {
        return false
      }
      if (!state.user.profile) {
        return false
      }
      if (state.user.profile_type.indexOf('GuestUser') > -1) {
        return false
      }

      return !state.user.profile.is_verified
    },
    currencies: (state) => {
      if (state.originalCurrencies !== 'undefined' && state.originalCurrencies.length > 0) {
        state.originalCurrencies.unshift('EUR')
      }
      return state.originalCurrencies
    }
  },
  actions: {
    // PINIA NEW FOR PINIA
    setUserList(payload) {
      //
      if (this.userList.length < 1) {
        this.userList = payload
      } else {
        payload.find((response) => {
          const flag = this.userList.find((user) => {
            return response.id === user.id
          })
          if (!flag) {
            this.userList.push(response)
          }
        })
      }

      if (this.filteredUserList.length < 0) {
        Array.prototype.forEach.call(payload, (stockUser) => {
          this.filteredUserList.push({
            ...stockUser,
            type: 'stockUser'
          })
        })
      } else {
        payload.find((stockUser) => {
          const temp = this.filteredUserList.find((user) => {
            return stockUser.id === user.id
          })
          if (!temp) {
            this.filteredUserList.push({
              ...stockUser,
              type: 'stockUser'
            })
          }
        })
      }
    },
    resetUserList() {
      // PINIA NEW FOR PINIA
      this.userList = []
    },
    setToken(token) {
      // PINIA NEW FOR PINIA
      this.accessToken = token
      auth.storeToken(token)
    },
    setUser(payload) {
      // PINIA NEW FOR PINIA
      localStorage.user = JSON.stringify(payload)
      this.user = payload
      this.profile = payload?.profile
    },
    setIdleStatus(boolean) {
      localStorage.idleStatus = JSON.stringify(boolean)
      this.idleStatus = boolean
    },
    setUserRoles(payload) {
      // PINIA NEW FOR PINIA
      if (payload !== '' && payload !== 'undefined' && payload !== undefined) {
        localStorage.roles = payload
        this.roles = JSON.parse(payload)
      }
    },
    async fetchInvestorSubscription() {
      try {
        const response = await repository.fetchInvestorSubscription()
        if (response.status === 200 && response.data) {
          this.investorPremium = response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async isCompanyPremium() {
      try {
        const response = await repository.isCompanyPremium()
        if (response.status === 200 && response.data) {
          this.isCompanyPremium = !!response.data
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async saveCurrency(payload) {
      try {
        this.loadingCurrency = true
        const response = await repository.saveCurrency(payload)
        if (response.status === 200 && response.data) {
          this.setUserProfile(response.data)
        }
        this.loadingCurrency = false
      } catch (e) {
        this.loadingCurrency = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async reportUser(payload) {
      this.loading = true
      try {
        const response = await repository.reportUser(payload)
        if (response.status === 201) {
          useSnackbarStore().snack({
            text: i18n.global.t('Report submitted successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: error,
          show: true,
          autoclose: true
        })
      }
    },

    async setSuccessUrl(payload) {
      this.successUrl = payload.type
    },
    async updateSubscription(data) {
      const response = await repository.updateSubscription(data)
      if (response.status === 200 && response.data) {
        this.setUser(response.data)
        router.go(-1)
      }
    },
    async cancelUserSubscription() {
      try {
        this.loadingStripeCheckout = true
        const response = await repository.cancelUserSubscription()
        if (response.status === 200 && response.data) {
          this.setUser(response.data)
        }
        this.loadingStripeCheckout = false
      } catch (e) {
        this.loadingStripeCheckout = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async createCompanyCheckoutSession(companyId) {
      try {
        this.loading = true
        this.loadingStripeCheckout = true
        const response = await repository.createCompanyCheckoutSession(companyId, {
          return_url: this.successUrl
        })
        if (response.status === 200 && response.data) {
          if (response.data.checkoutSessionId !== null) {
            sessionStorage.onboarding = JSON.stringify({})
            await window.Stripe(response.data.stripeKey).redirectToCheckout({
              sessionId: response.data.checkoutSessionId
            })
          } else {
            console.log('something went wrong.')
            // router.go(-1)
          }
        } else if (response.status === 204) {
          useSnackbarStore().snack({
            type: 'info',
            text: 'Company already have an active subscription or You do not have permissions.',
            show: true,
            autoclose: true
          })
        }
        this.loading = false
        this.loadingStripeCheckout = false
      } catch (e) {
        this.loading = false
        this.loadingStripeCheckout = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async createCompanyPollsCheckoutSession(companyId) {
      try {
        this.loading = true
        this.loadingStripeCheckout = true
        const response = await repository.createCompanyPollsCheckoutSession(companyId, { return_url: this.successUrl })
        if (response.status === 200 && response.data) {
          if (response.data.checkoutSessionId !== null) {
            sessionStorage.onboarding = JSON.stringify({})
            await window.Stripe(response.data.stripeKey).redirectToCheckout({
              sessionId: response.data.checkoutSessionId
            })
          } else {
            console.log('something went wrong.')
          }
        } else if (response.status === 204) {
          alert('Company already have an active subscription or You do not have permissions.')
          // const snackBar = useSnackbarStore()
          // snackBar.snack({
          //   type: 'info',
          //   text: 'Company already have an active subscription or You do not have permissions.',
          //   show: true,
          //   autoclose: true
          // })
        }
        this.loading = false
        this.loadingStripeCheckout = false
      } catch (e) {
        this.loading = false
        this.loadingStripeCheckout = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async createCheckoutSession() {
      try {
        this.loading = true
        this.loadingStripeCheckout = true
        const response = await repository.createCheckoutSession({
          return_url: this.successUrl
        })
        if (response.status === 200 && response.data) {
          if (response.data.checkoutSessionId !== null) {
            await window.Stripe(response.data.stripeKey).redirectToCheckout({
              sessionId: response.data.checkoutSessionId
            })
          } else {
            console.log('something went wrong.')
            // router.go(-1)
          }
        } else if (response.status === 204) {
          useSnackbarStore().snack({
            type: 'info',
            text: 'You already have an active subscription.',
            show: true,
            autoclose: true
          })
        }
        this.loading = false
        this.loadingStripeCheckout = false
      } catch (e) {
        this.loading = false
        this.loadingStripeCheckout = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async checkPollSubscription() {
      try {
        this.loading = true
        const response = await repository.checkPollSubscription()
        if (response.status === 200 && response.data) {
          await this.setUser(response.data.user)
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async checkSubscription() {
      try {
        this.loading = true
        const response = await repository.checkSubscription()

        if (response.status === 201) {
          sessionStorage.removeItem('onboarding')
        } else if (response.status === 200 && response.data) {
          await this.setUser(response.data.user)
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async addCompaniesToUser(payload) {
      try {
        this.loading = true
        const response = await repository.addCompaniesToUser(payload.user.id, {
          companyList: payload.companyList
        })
        if (response.status === 200 && !response.data.invalid) {
          this.usersCompany = response.data
        } else {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async removeCompaniesRole(payload) {
      try {
        this.loading = true
        const response = await repository.removeCompaniesRole(payload.userId, {
          role: payload.role
        })
        if (response.status === 200 && !response.data.invalid) {
          this.usersCompany = response.data
        } else {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async seenBubbleTag(payload) {
      const response = await repository.seenBubble({
        tag: payload.tag
      })

      if (response && response.status === 200) {
        if (payload.timer) {
          setTimeout(() => {
            //DISPATCH PREVIOUS
            this.fetch()
          }, 5000)
        } else {
          //DISPATCH PREVIOUS
          this.fetch()
        }
      }
    },
    async seenBubbleType(payload) {
      const response = await repository.seenBubble({
        tag: payload.tag,
        type: payload.type
      })

      if (response && response.status === 200) {
        if (response && response.status === 200) {
          if (payload.timer) {
            setTimeout(() => {
              //DISPATCH PREVIOUS
              this.fetch()
            }, 5000)
          } else {
            //DISPATCH PREVIOUS
            this.fetch()
          }
        }
      }
    },
    setLoadingUsers(loading) {
      this.loadingUsers = loading
    },
    async usersCompanies(user) {
      try {
        this.loadingUsers = true
        const response = await repository.usersCompanies(user.id)
        if (response.status === 200 && !response.data.invalid) {
          this.usersCompany = response.data
        } else {
          useSnackbarStore().snack({
            text: i18n.global.t('User does not have permission.'),
            type: 'error',
            show: true,
            autoclose: true
          })
        }
        this.loadingUsers = false
      } catch (e) {
        this.loadingUsers = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    visited(toPath) {
      repository.visited({
        path: toPath
      })
    },
    async fetchDSecret() {
      if (!auth.getDSecret()) {
        const response = await repository.dsecret(useAppsStore().platformKey)
        if (response.status === 200 && response.data) {
          auth.storeDSecret(response.data.dsecret)
        }
      } else {
        auth.setDSecretHeader(auth.getDSecret())
      }
    },
    setDSecret(dsecret) {
      this.dsecret = dsecret
    },
    async one(payload) {
      let stockUserId = this.selectedOtherUserId
      if (payload === null || payload === undefined) {
        this.loading = true
      } else {
        stockUserId = payload
        try {
          const response = await repository.one(stockUserId)
          if (response.status === 200 && response.data) {
            this.otherUser = {
              ...response.data
            }
          }
        } catch (error) {
          this.otherUser = null
        }
      }

      this.loading = false
    },
    async fetchUsers(payload) {
      try {
        this.loading = true
        let list = []
        list = payload.filter((userId) => this.userList.findIndex((user) => Number(user.id) === Number(userId)) < 0)
        if (list.length > 0) {
          const response = await repository.fetchUserList({
            users: list
          })
          if (response.status === 200 && response.data) {
            this.setUserList(response.data)
          }
        }
        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchMultiUsers() {
      try {
        this.loading = true

        const response = await repository.fetchMultiUsers()
        if (response.status === 200 && response.data) {
          if (response.data !== '' && response.data !== 'undefined' && response.data !== undefined) {
            this.multiUsers = response.data
          }
        }

        this.loading = false
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setUserBlockedStatus(boolean) {
      this.userBlocked = boolean
    },
    setUserProfile(payload) {
      this.user = {
        ...this.user,
        profile: payload
      }
      this.profile = payload
      this.locked_at = payload.locked_at
      this.setLockedTo(payload.locked_to)

      localStorage.user = JSON.stringify(this.user)
    },
    setLockedTo(data) {
      localStorage.locked_to = data
      this.locked_to = data
      if (differenceInSeconds(new Date(this.locked_to), new Date()) > 0) this.userBlocked = true
    },
    async fetchUserById(id) {
      const user = this.userList.find((user) => Number(user.id) === Number(id)) ? 1 : 0
      if (user === 0) {
        const response = await repository.one(id)
        if (response.status === 200 && response.data) {
          this.setUserList([response.data])
        }
      }
    },
    async removeSavedData(id) {
      try {
        const response = await repository.removeSaved(id)
        if (response.status === 204) {
          this.saved.splice(
            this.saved.findIndex((data) => data.id === id),
            1
          )
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchSaved() {
      try {
        this.savedLoading = true
        const response = await repository.fetchSaved()
        this.savedLoading = false
        if (response.status === 200) {
          this.nextPageUrl = response.data.next_page_url
          response.data.data.filter((saved) => {
            if (saved.saveable_type === 'App\\Models\\Post') {
              let data = saved.saveable
              if (data.postable.message && data.postable.message.match(mentions.MATCHING)) {
                data.postable.message = mentions.parse(data.postable.message, data.message.mentions)
              }
              // console.log(data)
            }
          })
          this.saved = [...response.data.data]
        }
      } catch (e) {
        this.savedLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setOnline() {
      const response = repository.setOnline()
      if (response.status === 204) {
        // console.log('user is online')
      }
    },
    async fetchProfile() {
      try {
        this.loading = true
        const response = await repository.profile()
        this.loading = false
        if (response.status === 200) {
          this.setUserProfile(response.data)
        } else {
          // console.log('No response found')
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async checkUser() {
      try {
        await repository.me()
      } catch (e) {
        if (e.response.status === 403) {
          this.loading = false
          this.logout()
          useSnackbarStore().snack({
            text: 'User has been deleted',
            type: 'info',
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async fetchStockUserInfo(payload) {
      try {
        this.loadingUsers = true
        this.resetUserList()
        const response = await repository.fetchStockUserInfo(payload.companyId, payload)
        if (response.status === 200 && response.data) {
          this.setUserList(response.data.data)
          this.nextPageUrl = response.data.next_page_url
        }
        this.loadingUsers = false
      } catch (e) {
        this.loadingUsers = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchAll(payload) {
      try {
        this.loadingUsers = true
        const response = await repository.fetchAllUsers(payload)
        if (response.status === 200 && response.data) {
          await this.resetUserList()
          this.setUserList(response.data.data)
          this.nextPageUrl = response.data.next_page_url
        }
        this.loadingUsers = false
      } catch (e) {
        useSnackbarStore().snack({
          text: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async searchAll(payload) {
      this.loadingUsers = true
      this.resetUserList()
      //DISPATCH PREVIOUS
      await this.fetchAll(payload)
      // try {
      //   this.loadingUsers = true
      //   const response = await repository.fetchAllUsers(payload)
      //   this.loadingUsers = false
      //   if ((response.status === 200) && response.data) {
      //     this.setUserList(response.data.data)
      //     this.nextPageUrl = response.data.next_page_url
      //   }
      // } catch (e) {
      //   useSnackbarStore().snack({ type: 'error', e: e, show: true, autoclose: true })
      // }
    },
    async fetchFollowings() {
      if (this.user.profile_type === 'App\\Models\\GuestUser') {
        return
      }

      try {
        this.loadingFollowings = true
        const response = await repository.fetchFollowings(this.user.profile.id)
        this.loadingFollowings = false
        if (response.status === 200 && response.data) {
          this.user.profile.followings = response.data

          this.userFollowings = response.data

          this.setUser({
            ...this.user
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async fetchOtherProfileFollowers(id) {
      if (id) {
        try {
          this.loadingFollowers = true
          const response = await repository.fetchFollowers(id)
          this.loadingFollowers = false

          if (response.status === 200 && response.data) {
            this.followers = response.data
            this.setUser(this.user)
          }
        } catch (e) {
          this.loadingFollowers = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async fetchOtherProfileFollowings(id) {
      if (!id) {
        return
      }

      try {
        this.loadingFollowings = true
        const response = await repository.fetchFollowings(id)
        this.loadingFollowings = false

        if (response.status === 200 && response.data) {
          this.followings = response.data
          this.setUser(this.user)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async warningSeen(payload) {
      try {
        const response = await repository.warningSeen(payload)
        if (response.status === 200 && response.data) {
          this.setUser(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async setShowBecomePremium(payload) {
      this.showBecomePremium = payload
    },
    selectUser(userId) {
      this.selectedOtherUserId = userId
    },
    async fetch() {
      if (auth.getAccessToken()) {
        // console.log('TOKEN TOKEN TOKEN', auth.getAccessToken())

        this.setToken(auth.getAccessToken())
      }

      // console.log('USER FETCH USER FETCH USER FETCH')

      if (localStorage.user !== undefined && localStorage.user !== '') {
        this.setUser(JSON.parse(localStorage.user))
        this.setUserRoles(localStorage.roles)
      }
      if (localStorage.idleStatus !== undefined && localStorage.idleStatus !== '') {
        this.setIdleStatus(JSON.parse(localStorage.idleStatus))
      }
      if (localStorage.getItem('isLoggedIn')) {
        this.isLoggedin = JSON.parse(localStorage.isLoggedIn)
      }

      // console.log('USER TOKEN USER TOKEN USER TOKEN')
      if (this.accessToken) {
        // console.log('USER TOKEN USER TOKEN USER TOKEN')
        useJobsStore().runEndpoint(
          'fetchProfile',
          async () => {
            try {
              const response = await repository.me()
              if (response.data.username !== 'Guest') {
                // console.log('USER DONE USER DONE USER DONE')
                this.setUser(response.data)

                this.setUserRoles(JSON.stringify(response.data.roles))
              } else {
                this.isLoggedIn = false
                localStorage.setItem('isLoggedIn', false)
              }
            } catch (e) {
              useSnackbarStore().snack({
                type: 'error',
                e: e,
                show: true,
                autoclose: true
              })
            }
          },
          1000 * 10
        )
      }
    },
    setAccessToken(token) {
      this.setToken(token)
    },
    setAlert(alert) {
      this.alert = {
        ...alert
      }
    },
    updateFCMToken() {
      if (localStorage.getItem('fcmToken') && auth.getAccessToken()) {
        //DISPATCH PREVIOUS
        this.setFirebaseToken(localStorage.getItem('fcmToken'))
      }
    },
    async login(payload) {
      try {
        this.loadingLogin = true

        const response = await repository.login({ email: payload.email, password: payload.password })
        this.loadingLogin = false
        if (response.status === 200) {
          if (response.data.token && response.data.token.length > 0) {
            // console.log('login data', response.data.user)
            this.isLoggedin = true
            //DISPATCH PREVIOUS
            this.setFirebaseToken(localStorage.getItem('fcmToken'))
            this.setToken(response.data.token)
            this.setUser(response.data.user)
            this.setIdleStatus(false)
            this.locked_at = response.data.user.profile.locked_at
            this.setLockedTo(response.data.user.profile.locked_to)
            this.setUserRoles(JSON.stringify(response.data.user.roles))
            //DISPATCH PREVIOUS
            this.fetchProfile()

            if (response.data.user.trading_account_id && payload.redirect && payload.redirect === 'dam-sso') {
              useDamStore().requestSession()
            } else {
              router.push({
                name: 'myFeed'
              })
            }
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })

        this.loadingLogin = false
        if (e.response) {
          this.loginError = e.response.data.message
        }
      }
    },
    async setUserIdle() {
      try {
        if (!this.idleStatus) {
          const response = await repository.setUserIdle()
          if (response.status === 200 && response.data) {
            this.setIdleStatus(!!response.data)
            useSnackbarStore().snack({
              text: i18n.global.t('No activity for more then 5 minutes please verify OTP to continue!'),
              type: 'info',
              show: true,
              autoclose: false
            })
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async sendTradingVerificationEmail() {
      try {
        if (this.idleStatus) {
          useSnackbarStore().snack({
            type: 'info',
            text: 'Session Expired. please verify otp sending to your registered email to continue!',
            show: true,
            autoclose: true
          })
          const response = await repository.sendTradingVerificationEmail()
          if (response.status === 204) {
            this.setShowOtpVerificationModal(true)
            useSnackbarStore().snack({
              type: 'success',
              text: 'Otp sent successfully!',
              show: true,
              autoclose: true
            })
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async verifyTradingEmail(payload) {
      this.loadingOTPCheck = true
      const response = await repository.verifyTradingEmail(payload)
      if (response.status === 200) {
        this.loadingOTPCheck = false
        if (response.data.status === 'success') {
          this.setIdleStatus(!!response.data.idle)
          this.setShowOtpVerificationModal(false)
          return 'success'
        } else {
          return response.data
        }
      }
    },
    async resendTradingOtp() {
      this.loadingOTPCheck = true
      const response = await repository.resendTradingOtp()
      if (response.status === 200) {
        this.loadingOTPCheck = false
        // console.log(response.data)
        useSnackbarStore().snack({
          text: i18n.global.t('Code generated successfully. please check out email for the code'),
          type: 'success',
          show: true,
          autoclose: true
        })
      }
    },
    async fetchIdleStatus() {
      try {
        if (this.profile.is_verified && this.profile.is_premium) {
          const response = await repository.fetchIdleStatus()
          if (response.status === 200 && response.data) {
            this.setIdleStatus(!!response.data.idle)
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async sendOtp(payload) {
      try {
        this.loadingLogin = true
        const response = await repository.sendOpt(payload)
        if (response.status === 200) {
          this.loadingLogin = false
          this.emailSent = true
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loadingLogin = false
        if (Number(e.response.status) === 422) {
          useSnackbarStore().snack({
            text: i18n.global.t('Email does not exists in our record. please enter correct email'),
            type: 'error',
            show: true,
            autoclose: false
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async sendSecondaryEmailVerification(payload) {
      try {
        this.loadingSecondaryEmail = true
        const response = await repository.sendSecondaryEmailOTP(payload)
        if (response.status === 200) {
          this.loadingSecondaryEmail = false
          this.secondaryEmailSent = true
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loadingLogin = false
        if (Number(e.response.status) === 422) {
          useSnackbarStore().snack({
            text: i18n.global.t('Email does not exists in our record. please enter correct email'),
            type: 'error',
            show: true,
            autoclose: false
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async otpVerificationForForgotPassword(payload) {
      try {
        this.loadingLogin = true
        const response = await repository.verifyEmailForForgotPassword(payload)

        if (response.status === 200 && response.data) {
          if (response.data.status && response.data.status === 'error') {
            this.loadingLogin = false
            let message = response.data.message ?? i18n.global.t('Something went wrong, please try again later')
            useSnackbarStore().snack({
              text: message,
              type: 'error',
              show: true,
              autoclose: false
            })
          } else if (response.data.status && response.data.status === 'success') {
            this.emailSent = false
            this.setNewPassword = true
            this.loadingLogin = false
          }
        }
      } catch (e) {
        this.loadingLogin = false
        if (Number(e.response.status) === 422) {
          useSnackbarStore().snack({
            text: i18n.global.t('Email does not exists in our record. please enter correct email'),
            type: 'error',
            show: true,
            autoclose: false
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async resetPassword(payload) {
      try {
        this.loadingLogin = true
        const response = await repository.resetPassword(payload)
        if (response.status === 200 && response.data) {
          this.setNewPassword = false
          this.loadingLogin = false
          if (response.data.token !== undefined && response.data.token.length > 0) {
            this.isLoggedin = true
            this.initFirebaseMessagingRegistration()
            this.setToken(response.data.token)
            this.setUser(response.data.user)
            // commit('SET_EMAIL_VERIFIED_AT', response.data.user.email_verified_at)
            //DISPATCH PREVIOUS
            this.fetchProfile()
            router.push({
              name: 'myFeed'
            })
          }
        }
      } catch (e) {
        this.loadingLogin = false
        this.setNewPassword = false
        if (Number(e.response.status) === 422) {
          useSnackbarStore().snack({
            text: i18n.global.t('Email does not exists in our record. please enter correct email'),
            type: 'error',
            show: true,
            autoclose: false
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async sendEmailVerification() {
      const response = await repository.emailVerificationNeeded(this.user.id)
      if (response.status === 200) {
        // console.log(response.data)
        useSnackbarStore().snack({
          text: i18n.global.t('Code generated successfully. please check out email for the code'),
          type: 'success',
          show: true,
          autoclose: true
        })
      }
    },
    async otpVerification(payload) {
      try {
        this.loadingOTPCheck = true
        const response = await repository.verifyEmailWithOTP(this.user.id, payload)
        this.loadingOTPCheck = false
        if (response.status === 200 && response.data) {
          if (response.data.status === 'error') {
            useSnackbarStore().snack({
              text: response.data.message,
              type: 'error',
              show: true,
              autoclose: true
            })
          } else {
            this.isRegistered = true
            this.isLoggedin = true
            await this.setUser(response.data.user)
            useSnackbarStore().snack({
              text: i18n.global.t('Email verified successfully'),
              type: 'success',
              show: true,
              autoclose: true
            })
            if (payload.process === 'signupEmailVerification') {
              const name = payload.userType === 'companyUser' ? 'onboarding-company' : 'onboarding'
              router.push({
                name
              })
            }
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async otpSecondaryEmailVerification(payload) {
      try {
        this.loadingOTPCheck = true
        const response = await repository.secondaryEmailverifyOTP(payload)
        this.loadingOTPCheck = false
        if (response.status === 200 && response.data) {
          if (response.data.status === 'error') {
            useSnackbarStore().snack({
              text: response.data.message,
              type: 'error',
              show: true,
              autoclose: true
            })
          } else {
            this.isRegistered = true
            this.isLoggedin = true
            await this.setUser(response.data.user)
            useSnackbarStore().snack({
              text: i18n.global.t('Email verified successfully'),
              type: 'success',
              show: true,
              autoclose: true
            })
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async register(payload) {
      this.loadingRegistration = true
      payload.timezone = this.timezone
      payload.platform_key = useAppsStore().platformKey
      try {
        const response = await repository.register(payload)
        this.loadingRegistration = false
        // console.log(response)
        if (response.status === 200) {
          if (response.data && response.data.errors) {
            if (response.data.errors.taken) {
              this.alreadyRegistered = true
            } else if (response.data.errors.email) {
              this.alreadyRegistered = true
            }
          } else {
            this.isRegistered = true
            this.isLoggedin = true
            await this.setToken(response.data.token)
            await this.setUser(response.data.user)
            await this.setUserRoles(JSON.stringify(response.data.user.roles))

            // if (!response.data.user.email_verified_at) {
            //DISPATCH PREVIOUS
            //   this.sendEmailVerification()
            // }

            // const name = (payload.userType === 'companyUser') ? 'onboarding-company' : 'onboarding'
            // router.push({
            //   name
            // })

            setTimeout(() => {
              this.isRegistered = false
            }, 3000)
          }
        } else {
          if (response.data.errors.taken) {
            this.alreadyRegistered = true
          } else if (response.data.errors.email) {
            this.alreadyRegistered = true
          }
          this.alreadyRegistered = true
          setTimeout(() => {
            this.alreadyRegistered = false
          }, 5000)
        }
      } catch (e) {
        this.loadingRegistration = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })

        if (e.response.data.errors.taken) {
          this.alreadyRegistered = true
        } else if (e.response.data.errors.email) {
          this.alreadyRegistered = true
        }

        this.errorRegistered = true
        setTimeout(() => {
          this.errorRegistered = false
        }, 5000)
      }
    },
    async follow(stockUserId = null) {
      try {
        let selectedOtherUserId = this.selectedOtherUserId
        if (stockUserId) {
          selectedOtherUserId = stockUserId
        }

        this.otherUser = {
          ...this.otherUser,
          is_follower: !this.otherUser.is_follower
        }

        const response = await repository.follow(selectedOtherUserId)
        if (response.status === 200) {
          //DISPATCH PREVIOUS
          const response = await repository.me()
          if (response.data.username !== 'Guest') {
            // console.log('USER DONE USER DONE USER DONE')
            this.setUser(response.data)

            this.setUserRoles(JSON.stringify(response.data.roles))
          }
          this.one(selectedOtherUserId)
          //DISPATCH PREVIOUS
          this.fetchFollowings()
          if (this.otherUser.is_follower) {
            useSnackbarStore().snack({
              text: i18n.global.t('User added to your following list'),
              type: 'success',
              show: true,
              autoclose: true
            })
          } else {
            useSnackbarStore().snack({
              text: i18n.global.t('User removed from your following list'),
              type: 'success',
              show: true,
              autoclose: true
            })
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async followerList(id) {
      try {
        const selectedOtherUserId = id
        const userIndex = this.followers.findIndex((stockUser) => stockUser.id === selectedOtherUserId)
        this.followers[userIndex].is_follower = !this.followers[userIndex].is_follower

        const response = await repository.follow(selectedOtherUserId)
        if (response.status === 200) {
          //DISPATCH PREVIOUS
          this.one(selectedOtherUserId)
          //DISPATCH PREVIOUS
          this.fetchFollowings()

          useSnackbarStore().snack({
            text: i18n.global.t('User added to your following list'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async followingList(id) {
      try {
        const selectedOtherUserId = id
        const userIndex = this.followings.findIndex((stockUser) => stockUser.id === selectedOtherUserId)
        this.followings[userIndex].is_follower = !this.followings[userIndex].is_follower

        const response = await repository.follow(selectedOtherUserId)
        if (response.status === 200) {
          //DISPATCH PREVIOUS
          this.one(selectedOtherUserId)
          //DISPATCH PREVIOUS
          this.fetchFollowings()

          useSnackbarStore().snack({
            text: i18n.global.t('User added to your following list'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async block(payload) {
      try {
        this.loadingBlock = true

        // const stockUser = rootthis.users.user.profile
        // const otherUser = this.otherUser

        // const followedIndex = stockUser.blockings.findIndex(user => (user.id === otherUser.profile_id))

        const response = await repository.block(this.selectedOtherUserId, payload)
        this.loadingBlock = false

        if (response.status === 204) {
          //DISPATCH PREVIOUS
          this.fetch()
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async firebaseRegister(payload) {
      this.loadingRegistration = true
      payload.timezone = this.timezone
      try {
        const response = await repository.firebaseRegister(payload)
        this.loadingRegistration = false
        this.loadingLogin = false
        this.loadingLogin = false
        if (response.status === 200) {
          this.isRegistered = true
          this.setToken(response.data.token)
          this.setUser(response.data.user)
          this.setUserRoles(JSON.stringify(response.data.user.roles))
          router.push({
            name: 'myFeed'
          })
          setTimeout(() => {
            this.isRegistered = false
          }, 3000)
        } else {
          this.errorRegistered = true
          setTimeout(() => {
            this.errorRegistered = false
          }, 5000)
        }
      } catch (e) {
        this.loadingRegistration = false
        this.errorRegistered = true
        setTimeout(() => {
          this.errorRegistered = false
        }, 5000)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    resetRegisterMessage() {
      this.alreadyRegistered = false
    },
    async resetError() {
      this.loginError = ''
      this.editUserError = ''
      this.otpConfirmationError = ''
    },
    async verifyMaybeLater() {
      this.maybeLater = true
    },
    async verifyNow() {
      this.maybeLater = false
    },
    async update(payload) {
      try {
        payload.timezone = this.timezone
        payload.name = this.user.profile.name
        this.loadingUpdate = true
        const response = await repository.update(payload)
        this.loadingUpdate = false
        if (response.status === 200) {
          //DISPATCH PREVIOUS
          this.fetch()
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })

        this.loadingUpdate = false
        if (e.data) this.loginError = e.data.message
        else this.loginError = 'Error when trying to save!'
      }
    },
    async profileImage(payload) {
      try {
        this.loadingProfileImage = true
        const response = await repository.profileImage(payload)
        if (response.status === 201) {
          this.user.profile = {
            ...this.user.profile,
            image: response.data.image
          }
          this.user = {
            ...this.user
          }
          localStorage.user = JSON.stringify(this.user)
        }
        this.loadingProfileImage = false
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async setLogo(payload) {
      const index = this.usersCompany.findIndex((company) => company.slug_url === payload.slug_url)
      console.log('abcd', payload, index)
      if (index > -1) {
        this.usersCompany[index].logo = payload.logo
        this.usersCompany[index].color = payload.color
        this.usersCompany = [...this.usersCompany]
      }
    },
    async setBanner(payload) {
      const index = this.usersCompany.findIndex((company) => company.slug_url === payload.slug_url)
      console.log('abcd', payload, index)
      if (index > -1) {
        this.usersCompany[index].banner = payload.banner
        this.usersCompany = [...this.usersCompany]
      }
    },
    async setProfileImageLoading(payload) {
      this.loadingProfileImage = payload
    },
    async logout() {
      try {
        // await firebaseLogout()

        localStorage.isLoggedIn = false
        this.setIdleStatus(false)
        this.setFirebaseToken(null)

        //DISPATCH PREVIOUS
        // this.setFirebaseToken(, null)

        //DISPATCH PREVIOUS
        // this.assets(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.boardMembers(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.calendarEvents(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.chats(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.comments(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.companies(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.contacts(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.documents(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.insiders(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.media(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.members(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.notifications(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.owners(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.pressRelease(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.timeline(reset', null, { root: true })
        //DISPATCH PREVIOUS
        // this.triggers(reset', null, { root: true })

        usePressReleasesStore().$reset()
        useOwnersStore().$reset()
        useNotificationsStore().$reset()
        useMembersStore().$reset()
        useMediasStore().$reset()
        useInsidersStore().$reset()
        useDocumentsStore().$reset()
        useContactsStore().$reset()
        useCompaniesStore().$reset()
        useCommentsStore().$reset()
        useCommentsStore().$reset()
        useChatsStore().$reset()
        useCalendarEventsStore().$reset()
        useBoardMembersStore().$reset()
        useAssetsStore().$reset()
        usePostsStore().$reset()
        // useTriggersStore().$reset()

        this.accessToken = ''
        this.setUser({})
        auth.clearToken()
        this.isLoggedin = false
        await this.guest()

        //DISPATCH PREVIOUS
        // this.socket(disconnect', null, { root: true })

        useSocketsStore().disconnect()

        router.push({ name: 'login' })
        // router.go()
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async guest() {
      if (!auth.getAccessToken()) {
        console.log('TRY GUEST')
        try {
          const response = await repository.guest()
          if (response.status === 200 && response.data.token !== undefined && response.data.token.length > 0) {
            this.accessToken = response.data.token
            auth.storeTemporaryToken(response.data.token)
            //DISPATCH PREVIOUS
            await this.fetch()
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    setVerifyTerms(boolean) {
      this.needToVerifyTerms = boolean
      localStorage.needToVerifyTerms = boolean
    },
    async frejaLogin(authCode) {
      try {
        this.loadingLogin = true

        const response = await repository.frejaLogin({
          reference: authCode,
          platform_key: useAppsStore().platformKey,
          native: useAppsStore().isNative
        })

        this.loadingLogin = false
        if (response.status === 200) {
          console.log('freja login 1')
          if (response.data.token !== undefined && response.data.token.length > 0) {
            console.log('freja login 2')
            console.log('login data', response.data.user)
            this.isLoggedin = true
            this.setToken(response.data.token)

            console.log('freja login 3')
            if (response.data.status === 'registered') {
              console.log('freja login 4')
              this.setVerifyTerms(true)
            } else {
              console.log('freja login 5')
              if (localStorage.needToVerifyTerms && JSON.parse(localStorage.needToVerifyTerms)) {
                console.log('freja login 6')
                this.setVerifyTerms(true)
              } else {
                console.log('freja login 7')
                this.setUser(response.data.user)
                this.setUserRoles(JSON.stringify(response.data.user.roles))
                //DISPATCH PREVIOUS
                this.fetchProfile()
                router.push({
                  name: 'myFeed'
                })
              }
            }
          }
        }

        this.loadingLogin = false
      } catch (e) {
        console.log(e)
        this.loadingLogin = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async googleLogin() {
      if (this.isLoggedin && this.user.profile) {
        //DISPATCH PREVIOUS
        this.fetchProfile()
        console.log(this.user.profile.full_name + ' is already loggedIn')
        router.push({
          name: 'myFeed'
        })
      } else {
        this.loadingLogin = true

        try {
          // googleFirebaseLogin()
          // const info = await Device.getInfo()
          // if (info.platform === 'web') {
          // } else if (info.platform === 'android' || info.platform === 'ios') {
          //   // await firebase.auth().signInWithRedirect(provider)
          //   // cfaSignIn('google.com').subscribe(
          //   //   (user) => console.log(user.displayName)
          //   // )
          // }
          // this.loadingLogin = false
        } catch (e) {
          console.log(e)
          this.loadingLogin = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async facebookLogin() {
      if (this.isLoggedin && this.user.profile) {
        //DISPATCH PREVIOUS
        this.fetchProfile()
        console.log(this.user.profile.full_name + ' is already loggedIn')
        router.push({
          name: 'myFeed'
        })
      } else {
        this.loadingLogin = true

        // const provider = new firebase.auth.FacebookAuthProvider()
        try {
          // facebookFirebaseLogin().then(result => {
          //   console.log('eresult', result)
          // }).catch(e => {
          //   console.log('e', e)
          // })
          // const info = await Device.getInfo()

          // if (info.platform === 'web') {
          //   await firebase.auth().signInWithPopup(provider)
          // } else if (info.platform === 'android' || info.platform === 'ios') {
          //   await firebase.auth().signInWithRedirect(provider)
          // }

          this.loadingLogin = false
        } catch (e) {
          this.loadingLogin = false
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async scroll() {
      try {
        if (this.nextPageUrl !== null && (!this.loadingNewPost || !this.loadingNew)) {
          this.loadingNew = true
          const response = await repository.scroll(this.nextPageUrl)
          if (response.status === 200 && response.data) {
            response.data.data.find((response) => {
              const flag = this.saved.find((data) => {
                return response.id === data.id
              })
              if (!flag || flag === undefined) {
                this.saved.push(response)
              }
            })
            this.nextPageUrl = response.data.next_page_url
            this.loadingNew = false
          }
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scrollUsers(search) {
      try {
        if (this.nextPageUrl !== null && !this.loadingNew) {
          this.loadingNew = true
          const response = await repository.scroll(this.nextPageUrl, search)
          if (response.status === 200 && response.data) {
            response.data.data.map((response) => {
              const flag = this.userList.find((data) => {
                return response.id === data.id
              })
              if (!flag || flag === undefined) {
                this.userList.push(response)
              }
            })
            this.nextPageUrl = response.data.next_page_url
            this.loadingNew = false
          }
        }
      } catch (e) {
        this.loadingNew = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setFCMToken(payload) {
      localStorage.setItem('FCMToken', payload.token)
      this.FCMToken = payload.token
      this.initFirebaseMessagingRegistration()
    },
    async initFirebaseMessagingRegistration() {
      console.log(this.FCMToken)
      await repository.saveFCMToken({
        token: this.FCMToken
      })
    },
    async setFirebaseToken(payload) {
      if (payload) {
        const response = await repository.saveFCMToken({
          token: payload,
          platform_key: useAppsStore().platformKey
        })
        if (response.status === 200) {
          console.log('Token saved successfully')
        }
      }
    },
    async edit(payload) {
      try {
        payload.type === 'info' ? (this.infoLoading = true) : (this.passwordLoading = true)
        const response = await repository.editRequest(payload)
        payload.type === 'info' ? (this.infoLoading = false) : (this.passwordLoading = false)
        if (response.status === 200) {
          useSnackbarStore().snack({
            text: i18n.global.t('Profile updated successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })

          if (payload.email !== this.user.email) {
            //DISPATCH PREVIOUS
            this.sendEmailVerification()
            this.setUser(response.data)
            this.setUserProfile(response.data.profile)
          }

          if (response.data.status === 'requested') {
            return false
          } else {
            // router.push({
            //   name: 'profile'
            // })
            //DISPATCH PREVIOUS
            this.fetch()
            return true
          }
        }
      } catch (e) {
        console.log('Error : ' + e)
        payload.type === 'info' ? (this.infoLoading = false) : (this.passwordLoading = false)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
        if (payload.process === 'verifyingEmail') {
          if (e.response.data.errors.taken) {
            this.alreadyRegistered = true
          } else if (e.response.data.errors.email) {
            this.alreadyRegistered = true
          }

          setTimeout(() => {
            this.alreadyRegistered = false
          }, 5000)
        }
        if (e && e.errors) this.editUserError = e.errors
        else if (e && e.data) this.editUserError = e.data.message
        else this.editUserError = 'Error when trying to update!'
        return true
      }
    },
    setEditUserError(payload) {
      this.editUserError = payload
    },
    setOtpConfirmationError(payload) {
      this.otpConfirmationError = payload
    },
    async deleteUser(payload) {
      try {
        this.loading = true
        const response = await repository.deleteUser(payload)
        this.loading = false
        if (response.status === 200) return true
        return false
      } catch (e) {
        console.log('Error : ' + e)
        this.loading = false
        return false
      }
    },
    async editConfirm(payload) {
      try {
        this.loading = true
        console.log(payload)
        const response = await repository.editConfirmation(payload)
        this.loading = false
        if (response.status === 200) {
          router.push({
            name: 'profile'
          })
          //DISPATCH PREVIOUS
          this.me()
        }
      } catch (e) {
        console.log('Error : ' + e)
        this.loading = false
        if (e.response.status === 401) {
          this.otpConfirmationError = 'Otp does not match.'
        } else if (e && e.data) this.otpConfirmationError = e.data.message
        else if (e && e.errors) this.otpConfirmationError = e.errors
        else this.otpConfirmationError = 'Error when trying to update!'
      }
    },
    initTheme() {
      const setItem = localStorage.activeTheme

      if (setItem) {
        this.setTheme(setItem)
      } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setTheme('dark')
      }
    },
    async setTheme(payload) {
      localStorage.activeTheme = payload
      this.activeTheme = payload
    },
    async setEmailSend(payload) {
      this.emailSent = payload
    },
    // async setNewPassword(payload) {
    //   this.setNewPassword = payload
    // },
    async setShowOtpVerificationModal(boolean) {
      this.showOtpVerificationModal = boolean
    },
    async setShowVerificationModal(payload) {
      this.showVerificationModal = payload
    },
    async removeAllBubbles() {
      this.loading = true
      const response = await repository.removeAllBubbles()

      if (response.status === 204) {
        //DISPATCH PREVIOUS
        await this.fetch()
        //  useSnackbarStore().snack({ text: i18n.global.t('User added to your following list'), type: 'success', show: true, autoclose: true })
      }
      this.loading = false
    },
    async fetchCurrencies() {
      const time = this.originalCurrencies.length === 0 ? 0 : 1000 * 60 * 10

      useJobsStore().runEndpoint(
        'fetchCurrencies',
        async () => {
          try {
            const response = await repository.fetchCurrencies()
            this.originalCurrencies = response.data
          } catch (e) {
            useSnackbarStore().snack({
              type: 'error',
              e: e,
              show: true,
              autoclose: true
            })
          }
        },
        time
      )
    },
    async fetchCountries() {
      const time = this.countries.length === 0 ? 0 : 1000 * 60 * 10

      useJobsStore().runEndpoint(
        'fetchCountries',
        async () => {
          try {
            const response = await repository.fetchCountries()
            this.countries = response.data
          } catch (e) {
            useSnackbarStore().snack({
              type: 'error',
              e: e,
              show: true,
              autoclose: true
            })
          }
        },
        time
      )
    },

    async submitContact(payload) {
      this.loading = true
      try {
        const response = await repository.submitContact(payload)
        if (response.status === 201) {
          useSnackbarStore().snack({
            text: i18n.global.t('Form submitted successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: error,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchIsdCodes() {
      try {
        const response = await repository.fetchIsdCodes()
        this.isdCodes = response.data
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async getTradingAccountDetails() {
      try {
        const response = await repository.getTradingAccountDetails()
        this.userTradingAccountDetails = response.data
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchCompanyReports() {
      try {
        this.loadingCompanyReports = true
        const response = await repository.fetchCompanyReports()
        if (response.status === 200 && response.data) {
          this.companyReports = response.data.data
          this.companyReportsNextPageUrl = response.data.next_page_url
          this.loadingCompanyReports = false
        }
      } catch (e) {
        this.loadingCompanyReports = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async submitPoi(payload, poiFile) {
      try {
        let maxfilesize = 10 * 1024 * 1024 // 10 Mb
        this.setPoiError()
        if (poiFile.size > maxfilesize) {
          let label = i18n.global.t('Document should be maximun size of 10 MB.')
          this.poiError = label
          console.error(label)

          useSnackbarStore().snack({
            type: 'error',
            text: label,
            show: true,
            autoclose: true
          })
          return
        }

        if ((payload.doc_type === 'Driver License' || payload.doc_type === 'Passport') && !payload.expiration_date) {
          let label = i18n.global.t('Please provide expiration date')
          this.poiError = label
          console.error(label)

          useSnackbarStore().snack({
            type: 'error',
            text: label,
            show: true,
            autoclose: true
          })
          return
        }

        this.loadingSubmitPoi = true
        const response = await repository.submitPoi(payload)
        this.loadingSubmitPoi = false
        if (response.status === 200 && response.data) {
          if (
            response.data.status === 'Accepted' ||
            response.data.status === 'Document is already processed or is not required'
          ) {
            useSnackbarStore().snack({
              text: i18n.global.t('Document submitted successfully'),
              type: 'success',
              show: true,
              autoclose: true
            })
          } else if (response.data.status === 'Received- being processed') {
            useSnackbarStore().snack({
              text: i18n.global.t('Document submitted successfully, and is being processed.'),
              type: 'success',
              show: true,
              autoclose: true
            })
          } else {
            this.setPoiError(response.data.errors)
          }
          console.log('response.data', response.data)
        }
      } catch (e) {
        console.error(e)
        this.loadingUpdate = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async scrollCompanyReports() {
      try {
        if (this.companyReportsNextPageUrl !== null && !this.loadingNewCompanyReports) {
          this.loadingNewCompanyReports = true
          const response = await repository.scroll(this.companyReportsNextPageUrl)
          if (response.status === 200 && response.data) {
            this.companyReports.push(...response.data.data)
            this.companyReportsNextPageUrl = response.data.next_page_url
          }
          this.loadingNewCompanyReports = false
        }
      } catch (e) {
        this.loadingNewCompanyReports = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setPoiError(data) {
      this.poiError = data
    },
    setPoaError(data) {
      this.poaError = data
    },
    async submitPoa(payload, poaFile) {
      try {
        let maxfilesize = 10 * 1024 * 1024 // 10 Mb
        this.setPoaError()
        if (poaFile.size > maxfilesize) {
          let label = i18n.global.t('Document should be maximun size of 10 MB.')
          console.error(label)
          this.setPoaError(label)
          return
        }

        if ((payload.doc_type === 'Driver License' || payload.doc_type === 'Passport') && !payload.expiration_date) {
          let label = i18n.global.t('Please provide expiration date')

          this.setPoaError(label)

          useSnackbarStore().snack({
            type: 'error',
            text: label,
            show: true,
            autoclose: true
          })
          return
        }

        this.loadingSubmitPoa = true
        const response = await repository.submitPoa(payload)
        this.loadingSubmitPoa = false
        if (response.status === 200 && response.data) {
          if (
            response.data.status === 'Accepted' ||
            response.data.status === 'Document is already processed or is not required'
          ) {
            useSnackbarStore().snack({
              text: i18n.global.t('Document submitted successfully'),
              type: 'success',
              show: true,
              autoclose: true
            })
          } else if (response.data.status === 'Received- being processed') {
            useSnackbarStore().snack({
              text: i18n.global.t('Document submitted successfully, and is being processed.'),
              type: 'success',
              show: true,
              autoclose: true
            })
          } else {
            this.setPoaError(response.data.errors)
          }
          console.log('response.data', response.data)
        }
      } catch (e) {
        console.error(e)
        this.loadingUpdate = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async uploadBanner(payload) {
      try {
        const response = await repository.uploadBanner(payload)
        if (response.status === 200) {
          this.me()
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    }
  }
})
