<template>
  <input
    v-if="pattern"
    :id="inputId"
    :ref="inputRef"
    :pattern="pattern"
    :readonly="readOnly"
    :maxlength="maxlength"
    :minlength="minlength"
    :required="required"
    :title="title"
    :step="step"
    :type="type"
    :value="modelValue"
    :placeholder="placeholder"
    :disabled="locked"
    :class="[classes,borderClass ,rounded,padding,iphoneClass,shadow,hasDarkMode ? 'dark:text-custom-purple-100 dark:placeholder-custom-purple-200 dark:bg-custom-purple-450' :'','px-4 py-2 text-sm rounded-sm focus:outline-none focus:ring-0']"
    :autofocus="autofocus"
    @keyup.enter="$emit('submit')"
    @input="$emit('update:model-value', $event)"
    @focus="$emit('focus', $event)"
  >
  <input
    v-else
    :id="inputId"
    :ref="inputRef"
    :readonly="readOnly"
    :maxlength="maxlength"
    :minlength="minlength"
    :required="required"
    :step="step"
    :type="type"
    :value="modelValue"
    :placeholder="placeholder"
    :disabled="locked"
    :class="[classes,borderClass ,rounded,padding,iphoneClass,shadow,hasDarkMode ? 'dark:text-custom-purple-100 dark:placeholder-custom-purple-200 dark:bg-custom-purple-450' :'','px-4 py-2 text-sm rounded-sm focus:outline-none focus:ring-0']"
    :autofocus="autofocus"
    @keyup.enter="$emit('submit')"
    @input="$emit('update:model-value', $event)"
    @focus="$emit('focus', $event)"
  >
</template>

<script>

import { Capacitor } from '@capacitor/core'
import { isIphone } from '@/js/helpers/mobile'

export default {
  name: 'BaseInput',
  props: {
    inputId: { type: String, default: '' },
    inputRef: { type: String, default: '' },
    required: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    modelValue: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    classes: { type: String, default: '' },
    locked: { type: Boolean, default: false },
    rounded: { type: String, default: 'rounded-none' },
    shadow: { type: String, default: 'shadow-none' },
    padding: { type: String, default: 'px-4 py-2' },
    readOnly: { type: Boolean, default: false },
    border: { type: String, default: 'border border-gray-300 dark:border-custom-purple-300' },
    maxlength: { type: Number, default: 500 },
    minlength: { type: Number, default: 0 },
    step: { type: Number, default: null },
    hasDarkMode: { type: Boolean, default: true },
    pattern: { type: String, default: null },
    title:{ type: String, default: '' },
	autofocus: { type: Boolean, default: true }
  },
  emits: [
    'submit',
    'update:model-value',
    'focus'
  ],
  computed: {
    iphoneClass () {
      if ((Capacitor.getPlatform() === 'ios') || isIphone()) {
        return 'input-iphone-size'
      }

      return ''
    },
    borderClass(){
      if (this.hasDarkMode) {
        return this.border
      }else{
        return this.border.replace(/\bdark:[\w-]+\s*/g, '');
      }
    }
  },
  mounted() {
    let input = document.querySelector('[autofocus]');
    if (input) {
      input.focus();
    }
  },
  methods: {
    test () {
      console.log("hej")
    }
  }
}
</script>

<style lang="scss">
input::placeholder {
  font-size: 14px;
}
.input-iphone-size {
  font-size: 1em;
}
@media only screen and (max-width: 600px) {
  input::placeholder {
    font-size: 16px;
  }
}
</style>
