<template>
      <div id="notificationDiv" class=" mt-15 sm:mt-0 w-screen h-screen sm:w-96 sm:h-96 overflow-hidden overflow-y-auto z-70 px-2 bg-white text-custom-purple-500 dark:text-custom-purple-100 dark:bg-custom-purple-500 pb-4 pt-0  sm:rounded-xl shadow-sm dark:shadow-sm-dark absolute -top-3 sm:top-10 right-[-16px] sm:right-5">
      <div>
        <Title class="px-2 py-4">
          Notifications
        </Title>
        <Icon
          name="x"
          class="text-3xl absolute top-1 right-2 p-2 cursor-pointer sm:block hidden"
          @click="$emit('menu-clicked', $event)"
        />
      </div>
  <div class="dark:text-custom-purple-100">
    <div
      v-if="loading"
      class="flex justify-center p-8"
    >
      <Loader />
    </div>
    <div v-else-if="notifications && notifications.length > 0">
      <div class="flex justify-end">
        <span
          class="inline-block text-xs p-2 border bg-white dark:bg-custom-purple-300 dark:text-white dark:border-custom-purple-600 cursor-pointer rounded-sm border-gray-900 my-4 font-normal text-gray-900"
          @click="clearAll"
        >
          {{ $t('Clear all notifications') }}
        </span>
      </div>
      <div
        v-for="(notification, index) in notifications"
        :key="('not' + notification.id + index)"
        @click="$emit('toggle-notification-menu')"
      >
        <div
          v-if="notification.pivot.show === 1"
          class="mb-1"
        >
          <Component
            :is="notifiableType(notification.notifiable_type)"
            :notification="notification"
            :time="diffFromNow(notification.created_at)"
            @remove="remove"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full text-center dark:text-custom-purple-100 p-8"
    >
      {{ $t('No notifications to show') }}
    </div>
    <div
      v-if="scrollLoading"
      class="flex justify-center p-8"
    >
      <Loader />
    </div>
  </div>
  </div>
</template>

<script>
import time from '@/js/helpers/time'
import types from '@/enums/notificationTypes'
import { useUsersStore } from '../../stores/users'
import { useNotificationsStore } from '../../stores/notifications'
import { useCommentsStore } from '../../stores/comments'
import { useSnackbarStore } from '../../stores/snackbar'

export default {
  name: 'NotificationsModule',
  setup () {
    const notificationsStore = useNotificationsStore()
    const usersStore = useUsersStore()
    const commentsStore = useCommentsStore()
    const snackbarStore = useSnackbarStore()

    return { notificationsStore, usersStore, commentsStore, snackbarStore }
  },
  computed: {
    notifications () {
      return this.notificationsStore.list
    },
    user () {
      return this.usersStore.user
    },
    loading () {
      return this.notificationsStore.loading
    },
    scrollLoading () {
      return this.notificationsStore.scrollLoading
    },
    internalNotifications () {
      let n = []

      n.push({ notifiable_type: 'testable' })

      n = [...n, ...this.notifications]

      return n
    }
  },
  mounted () {
    this.notificationsStore.fetch()
    const div = document.getElementById('notificationDiv')
    div.addEventListener('scroll', this.scroll);
  },
  created () {
    setTimeout(() => {
      this.notificationsStore.clearCounter()
      this.usersStore.seenBubbleType({ tag: 'global_menu_notification', type: 'global_menu_notification' })
    }, 500)
  },
  beforeUnmount () {
    this.notificationsStore.clearCounter()
    const div = document.getElementById('notificationDiv')
    
    div.removeEventListener('scroll', this.scroll);
  },
  methods: {
    diffFromNow (datetime) {
      return time.diffFromNow(datetime)
    },
    clearAll () {
      const confirmation = confirm(this.$t('Do you really want to clear all notifications?'))

      if (confirmation) {
        this.notificationsStore.hideAll()
      }
    },
    remove (payload) {
      this.notificationsStore.hide(payload)
    },
    validate (type, key) {
      return type === types[key]
    },
    notifiableType (notifiableType) {
      const type = notifiableType.replace('App\\Models\\', '')
      return type
    },
    async redirectPost (payload) {
      this.notificationsStore.read(payload.notificationId)
      this.$router.push({ name: 'posts', params: { id: payload.postId } })
    },
    async redirectCompanyIncident (payload) {
      this.notificationsStore.read(payload.notificationId)
      this.$router.push({ name: 'incident', params: { id: payload.incidentId } })
    },
    async redirectGeneralIncident (payload) {
      this.notificationsStore.read(payload.notificationId)
      this.$router.push({ name: 'incident', params: { id: payload.incidentId } })
    },
    async redirectComment (payload) {
      this.notificationsStore.read(payload.notificationId)
      await this.commentsStore.pushComment(payload.comment)
      if (payload.comment.commentable_type === 'App\\Models\\RoomPost') {
        this.$router.push({ name: 'posts', params: { id: payload.comment.commentable_id } })
      }

      if (payload.comment.commentable_type === 'App\\Models\\Comment') {
        this.$router.push({ name: 'comment', params: { id: payload.comment.post_id, commentId: payload.commentable_id } })
      }
    },
    redirect (payload) {
      this.notificationsStore.read(payload.notificationId)
      this.$router.push({ name: payload.name, params: { id: payload.id } })
    },
    redirectPressRelease (payload) {
      this.notificationsStore.read(payload.notificationId)
      if (payload.threadId) {
        this.$router.push({ name: payload.name, params: { id: payload.threadId} })
      } else {
        this.snackbarStore.snack({ type: 'error', text: 'thread not found', show: true, autoclose: true })
      }
    },
    redirectCompany (payload) {
      this.notificationsStore.read(payload.notificationId)
      this.$router.push({ name: payload.page, params: { id: payload.company.slug_url } })
    },
    redirectChat (payload) {
      this.notificationsStore.read(payload.notificationId)
      this.$router.push({ name: 'companyChat', params: { id: payload.company.slug_url, room: payload.room } })
    },
    redirectProfile (payload) {
      this.notificationsStore.read(payload.notificationId)
      this.$router.push({ name: 'myEucapsSettings' })
    },
    redirectPortfolio (payload) {
      this.notificationsStore.read(payload.notificationId)

      if(payload.query){
        this.$router.push({ name: 'myEucapsPortfolio',query:payload.query })
      }else{
        this.$router.push({ name: 'myEucapsPortfolio' })
      }
      
    },
    scroll () {
      const div = document.getElementById('notificationDiv')
      
      if (div.scrollTop + div.clientHeight >= div.scrollHeight) {
        this.notificationsStore.scroll()
      }
    }
  }
}
</script>
