<template>
  <div v-if="notification.notifiable">
    <Notification
      :pivot="notification.pivot"
      @remove="$emit('remove', { id: notification.id })"
    >
      <div @click="$parent.redirectComment({ comment: notification.notifiable.comment, notificationId: notification.id })">
        <AvatarSlot
          :stock-user="notification.notifiable.stock_user"
        >
          <div class="text-xs font-medium mr-6">
            <span class="font-bold">
              <RouterLink :to="{name: 'userFlow', params: {id: notification.notifiable.stock_user.id}}">{{ notification.notifiable.stock_user.username }}</RouterLink>
            </span>
            <span v-if="notification.total>1"> {{ $t('and') }}  {{ notification.total - 1 }} {{ $t('people') }}</span>
            <span v-if="notification.notifiable.comment.commentable_type === 'App\\Models\\CompanyPressRelease'">
              &nbsp; {{ $t('has commented on a press release that you follow') }}&nbsp;
            </span>
            <span v-else>&nbsp;{{ $t('has commented on your post') }} &nbsp;</span>
            <span v-if="notification.notifiable.company">
              {{ $t('for') }}&nbsp;
              <RouterLink
                class="font-bold"
                :to="{name: 'companyOverview', params: {id: notification.notifiable.company.slug_url}}"
              > {{ notification.notifiable.company.name }}</RouterLink>
            </span>
            <div>{{ time }}</div>
          </div>
        </AvatarSlot>
      </div>
    </Notification>
  </div>
</template>

<script>

import Notification from './Notification'

export default {
  name: 'NotificationNewComment',
  components: {
    Notification
  },
  props: {
    notification: { type: Object, default: () => {} },
    time: { type: String, default: '' }
  },
emits: [
'remove'
],
  mounted () {
    // console.log(this.notification)
  }
}
</script>
