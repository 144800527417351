import { RepositoryFactory } from "@/api/repositoryFactory"
import mentions from "../js/helpers/mentions"
const repository = RepositoryFactory.get("messages")
const postRepository = RepositoryFactory.get("posts")
const listRepository = RepositoryFactory.get("lists")
const companyRepository = RepositoryFactory.get('companies')

import { defineStore } from "pinia"
import { useSnackbarStore } from "./snackbar"
import { useUsersStore } from './users'
import { v4 as uuidv4 } from 'uuid'


export const useMessagesStore = defineStore("messages", {
  state: () => ({
    loading: false,
    loadingInternalAds: false,
    premiumCompanyNextPageUrl: '',
    messages: [],
    nextPageUrl: "",
    internalAds: [],
    feeds: [],
    nextInternalAdsUrl: '',
    scrollLoading: false,
    canScrollMore: false,
	newPosts: []
  }),
  actions: {
    setMessages(messages) {
      let messageList = []
      messages.forEach((message) => {
        if (message.messagable_type === "App\\Models\\RoomPost") {
          if (
            message.messagable.message &&
            (typeof message.messagable.message === "string" ||
              message.messagable.message instanceof String) &&
            message.messagable.message.match(mentions.MATCHING)
          ) {
            message.messagable.message = mentions.parse(
              message.messagable.message,
              message.mentions
            )
          }
          messageList.push(message)
        }

        if (message.messagable_type === "App\\Models\\Comment") {
          message.messagable.text = mentions.parse(
            message.messagable.text,
            message.mentions
          )

          messageList.push(message)
        }

        if (message.messagable_type === "App\\Models\\ChatMessage") {
          if (
            message.messagable.chatable.message &&
            message.messagable.chatable.message.match(mentions.MATCHING)
          ) {
            message.messagable.chatable.message = mentions.parse(
              message.messagable.chatable.message,
              message.messagable.message.mentions,
              "chat"
            )
          }

          messageList.push(message)
        }
      })
      this.messages = messageList
    },
    setFeeds(feeds) {
      let feedList = []
      feeds.forEach((feed) => {
        if(feed.feedable_type ==='App\\Models\\StockUser' && feed.type=== 'feed_post'  && feed.sub_feed.length > 0 ){
          if (feed.sub_feed[0].subfeedable_type === "App\\Models\\RoomPost") {
            if (
              feed.sub_feed[0]?.subfeedable?.message &&
              (typeof feed.sub_feed[0]?.subfeedable?.message === "string" ||
              feed.sub_feed[0]?.subfeedable?.message instanceof String) &&
              feed.sub_feed[0]?.subfeedable?.message.match(mentions.MATCHING)
            ) {
              feed.sub_feed[0].subfeedable.message = mentions.parse(
                feed.sub_feed[0].subfeedable.message,
                feed.sub_feed[0].subfeedable.messages.mentions
              )
              feed.sub_feed[0].subfeedable.message_english = mentions.parse(
                feed.sub_feed[0].subfeedable.message_english,
                feed.sub_feed[0].subfeedable.messages.mentions
              )
            }
            if(feed.sub_feed[0].subfeedable?.latest_comment?.length > 0){
              feed.sub_feed[0].subfeedable.latest_comment.forEach((comment) => {

              if (
                comment?.text &&
                (typeof comment?.text === "string" ||
                comment?.text instanceof String) &&
                comment?.text.match(mentions.MATCHING)
              ) {
                comment.text = mentions.parse(
                  comment.text,
                  comment.message.mentions
                )
                comment.text_english = mentions.parse(
                  comment.text_english,
                  comment.message.mentions
                )
              }
            })
  
          }
            feedList.push(feed)
          }

        }
        else if (feed.feedable_type === "App\\Models\\RoomPost" || feed.feedable_type === "App\\Models\\Room") {
            if (
              feed.feedable?.message &&
              (typeof feed.feedable?.message === "string" ||
              feed.feedable?.message instanceof String) &&
              feed.feedable?.message.match(mentions.MATCHING)
            ) {
              feed.feedable.message = mentions.parse(
                feed.feedable.message,
                feed.feedable.messages.mentions
              )
              feed.feedable.message_english = mentions.parse(
                feed.feedable.message_english,
                feed.feedable.messages.mentions
              )
            }

            feed.sub_feed.forEach((subfeed) =>{
            if(subfeed.subfeedable?.latest_comment?.length > 0){
              subfeed.subfeedable.latest_comment.forEach((comment) => {

              if (
                comment?.text &&
                (typeof comment?.text === "string" ||
                comment?.text instanceof String) &&
                comment?.text.match(mentions.MATCHING)
              ) {
                comment.text = mentions.parse(
                  comment.text,
                  comment.message.mentions
                )
                comment.text_english = mentions.parse(
                  comment.text_english,
                  comment.message.mentions
                )
              }
            })
  
          }})
          
            feedList.push(feed)
          }
        
        else if (feed.feedable_type === "App\\Models\\Comment") {
          feed.feedable.text = mentions.parse(
            feed.feedable.text,
            feed.mentions
          )

          feedList.push(feed)
        }
        else if (feed.feedable_type === "App\\Models\\ChatMessage") {
          if (
            feed?.feedable?.chatable?.message &&
            feed?.feedable?.chatable?.message.match(mentions.MATCHING)
          ) {
            feed.feedable.chatable.message = mentions.parse(
              feed.feedable.chatable.message,
              feed.feedable.message.mentions,
              "chat"
            )
          }

          feedList.push(feed)
        }
        else {
          feedList.push(feed)
        }
      })
      this.feeds = feedList
    },
    async fetchInternalAds() {
      try {
        const randomNum = Math.floor(Math.random() * 6) + 1;
        this.loadingInternalAds = true
        this.canScrollMore = true
        if ((randomNum === 1 || this.nextInternalAdsUrl === null) && this.premiumCompanyNextPageUrl === '') {
          const response = await companyRepository.premiumCompanies()
          if (response.status === 200 && response.data.data) {
            this.setInternalAds([ { type: 'company-list', data: response.data.data, id: uuidv4() } ])
          }
          this.premiumCompanyNextPageUrl = response?.data?.next_page_url ? response.data.next_page_url : null
        } else if ((randomNum === 1 || this.nextInternalAdsUrl === null) && this.premiumCompanyNextPageUrl !== null) {
          const response = await repository.scroll(this.premiumCompanyNextPageUrl)
          if (response.status === 200 && response.data.data) {
            this.setInternalAds([ { type: 'company-list', data: response.data.data, id: uuidv4() }])
          }
          this.premiumCompanyNextPageUrl = response?.data?.next_page_url ? response.data.next_page_url : null
        } else if (this.nextInternalAdsUrl === '') {
            const response = await repository.fetchInternalAds()
            if (response.status === 200 && response.data.data) {
              this.setInternalAds(response.data.data)
            }
            this.nextInternalAdsUrl = response?.data?.next_page_url ? response.data.next_page_url : null
        } else if (this.nextInternalAdsUrl !== null) {
          const response = await repository.scroll(this.nextInternalAdsUrl)
          if (response.status === 200 && response.data.data) {
            this.setInternalAds(response.data.data)
          }
          this.nextInternalAdsUrl = response?.data?.next_page_url ? response.data.next_page_url : null
        }
        this.loadingInternalAds = false
      } catch (error) {
        this.loadingInternalAds = false
        console.error('Error fetching premium content:', error);
        useSnackbarStore().snack({
          type: 'error',
          text: 'Error fetching premium company content',
          show: true,
          autoclose: true
        })
      }
    },
    setInternalAds(payload) {
      payload.find((response) => {
        if (response.type === 'company-list') {
          this.internalAds.push(response)
        } else {
          const flag = this.internalAds?.findIndex((post) => {
            return Number(response.id) === Number(post.id)
          })
          if (flag > -1) {
            this.internalAds[flag] = { ...response }
          } else {
            this.internalAds.push(response)
          }
        }
      })
      this.internalAds = [ ...this.internalAds ]
    },
    async fetchMessages(userId) {
      try {
        this.setMessages([])

        this.loading = true
        this.canScrollMore = true

        const response = await repository.fetchMessages(userId)
        if (response.status === 200 && response.data) {
          this.loading = false
          this.setMessages(response.data.data)
          this.nextPageUrl = response.data.next_page_url
        }
      } catch (e) {
        console.log("Error occured : " + e)
      }
    },
    async followList (payload) {
      try {
        const response = await listRepository.followList(payload.listId, useUsersStore().user.profile_id)
        if (response.status === 200 && response.data) {
          const feedIndex = this.feeds.findIndex((feed) => feed.feedable_type === 'App\\Models\\ListsStockUser' && feed.feedable_id === Number(payload.listId))
          this.feeds[feedIndex].feedable.is_follower = !this.feeds[feedIndex].feedable.is_follower
          this.feeds = [ ...this.feeds ]
        }
      } catch (e) {
        // this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async followCompany (payload) {
      try {
        const response = await companyRepository.follow(payload.companyId)
        if (response.status === 200 && response.data) {
          const feedIndex = this.feeds.findIndex((feed) => feed.feedable_type === 'App\\Models\\ListsStockUser' && feed.feedable_id === Number(payload.listId))
          const subFeedIndex = this.feeds[feedIndex].sub_feed.findIndex(subFeed => subFeed.id === payload.subFeedId)
          this.feeds[feedIndex].sub_feed[subFeedIndex].subfeedable.is_follower = response.data.is_follower
          this.feeds = [ ...this.feeds ]
        }
      } catch (e) {
        // this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async follow (postId, userPost) {
      try {
        // this.loading = true
        const response = await postRepository.follow(postId)
        // this.loading = false
        if (response.status === 200 && response.data) {
          if (userPost) {
			const feedIndex = this.feeds.findIndex((post) => post.feedable_type === 'App\\Models\\StockUser' && post.sub_feed[0]?.subfeedable_id === Number(postId))
            this.feeds[feedIndex].sub_feed[0].subfeedable.stock_user.is_follower = !this.feeds[feedIndex].sub_feed[0].subfeedable.stock_user.is_follower
			await useUsersStore().selectUser(this.feeds[feedIndex].sub_feed[0].subfeedable.stock_user.id)
			await useUsersStore().follow()
            this.feeds = [ ...this.feeds ]
          } else {
			const feedIndex = this.feeds.findIndex((post) => post.feedable_type === 'App\\Models\\RoomPost' || post.feedable_type === 'App\\Models\\StockUser' && post.feedable_id === Number(postId))
			this.feeds[feedIndex].feedable = response.data
			this.feeds = [ ...this.feeds ]
          }
        }
      } catch (e) {
        // this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async like(payload) {
      try {
        const feed = payload.newPost ? this.newPosts.find((post) => post.id === Number(payload.post_id)) : payload.userPost ? this.feeds.find((post) => post.feedable_type === 'App\\Models\\StockUser' && post.sub_feed[0]?.subfeedable.id === Number(payload.post_id)) : this.feeds.find((post) => post.feedable_type === 'App\\Models\\RoomPost' && post.feedable_id === Number(payload.post_id))
        const likeIndex = payload.newPost ? feed.likes.findIndex(
			(like) =>
              Number(like.stock_user_id) === Number(useUsersStore().user.profile_id) && like.reaction === payload.reaction
          ) : payload.userPost ? feed.sub_feed[0]?.subfeedable.likes.findIndex(
			(like) =>
              Number(like.stock_user_id) === Number(useUsersStore().user.profile_id) && like.reaction === payload.reaction
          ) : feed.feedable.likes.findIndex(
          (like) =>
            Number(like.stock_user_id) === Number(useUsersStore().user.profile_id) && like.reaction === payload.reaction
        )

        if (likeIndex > -1) {
			payload.newPost ? feed.likes.splice(likeIndex, 1) : payload.userPost ? feed.sub_feed[0]?.subfeedable.likes.splice(likeIndex, 1) : feed.feedable.likes.splice(likeIndex, 1)
        } else {
          payload.newPost ? feed.likes.push({
			likeable_id: payload.post_id,
			reaction: payload.reaction,
			stock_user: useUsersStore().user.profile,
			stock_user_id: useUsersStore().user.profile.id
          }) :
          payload.userPost ? feed.sub_feed[0]?.subfeedable.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: useUsersStore().user.profile,
            stock_user_id: useUsersStore().user.profile.id
          }) :
          feed.feedable.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: useUsersStore().user.profile,
            stock_user_id: useUsersStore().user.profile.id
          })
        }

        await postRepository.postLike(payload.post_id, {
          reaction: payload.reaction
        })

        this.feeds = [ ...this.feeds ]
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async likeCompanyDataPost(payload) {
      try {
        const feed = this.feeds.find((post) => post?.feedable_type === 'App\\Models\\Company' && post?.sub_feed[0]?.subfeedable?.id === Number(payload.post_id))
        const likeIndex = feed?.sub_feed[0]?.subfeedable?.likes.findIndex(
          (like) =>
            Number(like.stock_user_id) === Number(useUsersStore().user.profile_id) && like.reaction === payload.reaction
        )

        if (likeIndex > -1) {
          feed?.sub_feed[0]?.subfeedable?.likes.splice(likeIndex, 1)
        } else {
          feed?.sub_feed[0]?.subfeedable?.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: useUsersStore().user.profile,
            stock_user_id: useUsersStore().user.profile.id
          })
        }

        await postRepository.postLike(payload.post_id, {
          reaction: payload.reaction
        })

        this.feeds = [ ...this.feeds ]
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async likeInternalAds(payload) {
      try {
        const feed = this.internalAds.find((post) => post?.type !== 'company-list' && post?.id === Number(payload.post_id))
        const likeIndex = feed?.likes.findIndex(
          (like) =>
            Number(like.stock_user_id) === Number(useUsersStore().user.profile_id) && like.reaction === payload.reaction
        )

        if (likeIndex > -1) {
          feed?.likes.splice(likeIndex, 1)
        } else {
          feed?.likes.push({
            likeable_id: payload.post_id,
            reaction: payload.reaction,
            stock_user: useUsersStore().user.profile,
            stock_user_id: useUsersStore().user.profile.id
          })
        }

        await postRepository.postLike(payload.post_id, {
          reaction: payload.reaction
        })

        this.internalAds = [ ...this.internalAds ]
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async followCompanyDataPost(postId) {
      try {
        const response = await postRepository.follow(postId)
        if (response.status === 200 && response.data) {
          const feedIndex = this.feeds.findIndex((post) => post.feedable_type === 'App\\Models\\Company' && post?.sub_feed[0]?.subfeedable?.id === Number(postId))
          this.feeds[feedIndex].sub_feed[0].subfeedable = response.data
          this.feeds = [ ...this.feeds ]
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async followInternalAds(postId) {
      try {
        const response = await postRepository.follow(postId)
        if (response.status === 200 && response.data) {
          const feedIndex = this.internalAds.findIndex((post) => post.type !== 'company-list' && post?.id === Number(postId))
          this.internalAds[feedIndex] = response.data
          this.internalAds = [ ...this.internalAds ]
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true,
        })
      }
    },
    async fetchFeeds(userId) {
      try {
        this.setFeeds([])

        this.loading = true
        this.canScrollMore = true

        const response = await repository.fetchFeeds(userId)
        if (response.status === 200 && response.data) {
          this.loading = false
          this.setFeeds(response.data.data)
          this.nextPageUrl = response.data.next_page_url
          this.newPosts = localStorage.posts ? JSON.parse(localStorage.posts)?.reverse() : []
        }
      } catch (e) {
        console.log("Error occured : " + e)
      }
    },
    async deleteFeed(feedId) {
      this.loading = true
      const findIndex = this.feeds.findIndex(feed => feed.id === feedId)
      this.feeds.splice(findIndex, 1)
      this.feeds = [ ...this.feeds ]
      this.loading = false
    },
    async fetchFeed(feedId) {
      try {
        this.canScrollMore = true
        const response = await repository.fetchFeed(feedId)
        if (response.status === 200 && response.data) {
          this.loading = false
          const findIndex = this.feeds.findIndex(feed => feed.id === feedId)
          this.feeds[findIndex] = response.data
          this.feeds = [ ...this.feeds ]
        }
        this.canScrollMore = false
      } catch (e) {
        this.canScrollMore = false
        this.loading = false
        console.log("Error occured : " + e)
      }
    },
    async scrollFeeds() {
      try {
        if (this.nextPageUrl === null) {
          this.scrollLoading = false
          this.canScrollMore = false
        } else if (this.nextPageUrl !== null && !this.loading) {
          this.scrollLoading = true
          const response = await repository.scroll(this.nextPageUrl)

          if (response.status === 200 && response.data && response.data.data) {
            response.data.data.map((message) => {
              this.feeds.push(message)
            })
            this.nextPageUrl = response.data.next_page_url
          }
          await this.fetchInternalAds()
          this.scrollLoading = false
        }
      } catch (e) {
        this.scrollLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: "error",
          show: true,
          autoclose: true,
        })
      }
    },
    async scroll() {
      try {
        if (this.nextPageUrl === null) {
          this.scrollLoading = false
          this.canScrollMore = false
        } else if (this.nextPageUrl !== null && !this.loading) {
          this.scrollLoading = true
          const response = await repository.scroll(this.nextPageUrl)
          this.scrollLoading = false

          if (response.status === 200 && response.data && response.data.data) {
            response.data.data.map((message) => {
              this.messages.push(message)
            })
            this.nextPageUrl = response.data.next_page_url
            this.scrollLoading = false
          }
        }
      } catch (e) {
        this.scrollLoading = false
        useSnackbarStore().snack({
          text: e.response.data.message[0],
          type: "error",
          show: true,
          autoclose: true,
        })
      }
    },
  },
})
