import {
  RepositoryFactory
} from "@/api/repositoryFactory"
const repository = RepositoryFactory.get("countries")
import {
  defineStore
} from "pinia"
import {
  useSnackbarStore
} from "./snackbar"

export const useCountriesStore = defineStore("countries", {
      state: () => ({
        selectedCompanyId: null,
        countries: [],
        userCountries: [],
        loading: false,
        ip: "",
        countriesWithStates: [],
        countriesWithIsd: []
      }),
      actions: {
        async fetch() {
          try {
            this.loading = true
            const response = await repository.countries()
            this.loading = false
            if (response.status === 200 && response.data) {
              this.countries = response.data
            }
          } catch (e) {
            this.loading = false
            useSnackbarStore().snack({
              type: "error",
              e: e,
              show: true,
              autoclose: true,
            })
          }
        },
        async fetchIp() {
          try {
            this.loading = true
            const response = await repository.deviceIp()
            this.loading = false
            if (response.status === 200 && response.data) {
              this.ip = response.data
            }
          } catch (e) {
            this.loading = false
            useSnackbarStore().snack({
              type: "error",
              e: e,
              show: true,
              autoclose: true,
            })
          }
        },

        async fetchUserCountries() {
          try {
            this.loading = true
            const response = await repository.userCountries()
            this.loading = false
            if (response.status === 200 && response.data) {
              this.userCountries = response.data
            }
          } catch (e) {
            this.loading = false
            useSnackbarStore().snack({
              type: "error",
              e: e,
              show: true,
              autoclose: true,
            })
          }
        },
        async sort(id) {
          try {
            this.loading = true
            const response = await repository.sortCountries(id)
            this.loading = false
            if (response.status === 201 && response.data) {
              this.countries = response.data
            }
          } catch (e) {
            this.loading = false
            useSnackbarStore().snack({
              type: "error",
              e: e,
              show: true,
              autoclose: true,
            })
          }
        },
        async fetchWithStates() {
          try {
            this.loading = true
            const response = await repository.countriesWithStates()
            this.loading = false
            if (response.status === 200 && response.data) {
              this.countriesWithStates = response.data
            }
          } catch (e) {
            this.loading = false
            useSnackbarStore().snack({
              type: "error",
              e: e,
              show: true,
              autoclose: true,
            })
          }
        },
        async fetchWithIsd() {
          try {
            this.loading = true
            const response = await repository.countriesWithIsd()
            this.loading = false
            if (response.status === 200 && response.data) {
              this.countriesWithIsd = response.data
            }
          } catch (e) {
            this.loading = false
            useSnackbarStore().snack({
              type: "error",
              e: e,
              show: true,
              autoclose: true,
            })
          }
        },

        getCountry(ibkrCountryCode) {
          try {
            let country = {}
            if (this.countriesWithIsd.length > 0) {
              country = this.countriesWithIsd.find(country => {
                return country.ibkr_code === ibkrCountryCode
              })
            }
            return country
          } catch (e) {
           
            useSnackbarStore().snack({
              type: "error",
              e: e,
              show: true,
              autoclose: true,
            })
            return {}
          }
        },
      }
    })