import { createApp, h, markRaw } from 'vue'

import { createPinia } from 'pinia'
import VueSocialSharing from 'vue-social-sharing'

import App from './App.vue'
import router from './js/router'
import i18n from '@/js/language/'
import '@/assets/css/tailwind.scss'
import Vidle from 'v-idle-3'

import { registerComponents } from './js/autoload'

import './js/firebase'
import 'vue-multiselect/dist/vue-multiselect.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import './registerServiceWorker'
import Vue3TouchEvents from 'vue3-touch-events'
import { createMetaManager } from 'vue-meta'
//import './registerServiceWorker'
import Portal from 'vue3-portal'

import TextareaAutosize from 'vue-textarea-autosize'
// import { StatusBar, Animation } from '@capacitor/status-bar'
import Multiselect from 'vue-multiselect'

import Highcharts from 'highcharts'
import Stock from 'highcharts/modules/stock'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsVue from 'highcharts-vue'
import Flag from 'vue-country-flag-next'
import solidGauge from 'highcharts/modules/solid-gauge.js'

import 'floating-vue/dist/style.css'
import { VTooltip } from 'floating-vue'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import FormatterPlugin from './plugins/formatterPlugin'

Stock(Highcharts)
HighchartsExporting(Highcharts)
HighchartsMore(Highcharts)

const app = createApp({
  setup: () => () => h(App)
})

const pinia = createPinia()
pinia.use(({ store }) => {
store.router = markRaw(router)
})

// app.use(i18n)
app.use(VueSocialSharing);
app.use(pinia)
app.use(Vidle)
app.use(router)
app.use(Portal)
app.use(HighchartsVue)
solidGauge(Highcharts)
app.use(TextareaAutosize)
app.use(createMetaManager())
app.use(Vue3TouchEvents)
app.use(Flag)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('Multiselect', Multiselect)
app.directive('tooltip', VTooltip)
app.use(i18n)
registerComponents(app)
app.use(ToastPlugin);
app.use(FormatterPlugin)
app.mount('#app')

library.add(far, fas, fab)