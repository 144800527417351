<template>
  <div
    class="px-4 text-custom-purple-500 dark:text-white"
  >
    <p
      class=" text-3xl font-extrabold mt-2 cursor-pointer"
      @click="$router.push({ name: 'companyOverview', params: { id: company.slug_url } })"
    >
      {{ company.name }}
    </p>
    <p class="font-semibold mb-4">
      {{ company.followers_count }} followers
    </p>
  </div>
</template>

<script>
export default {
  name: 'CompanyNameAndFollowers',
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
