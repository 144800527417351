import { RepositoryFactory } from '@/api/repositoryFactory'
import i18n from '../js/language'
const devcodeID = RepositoryFactory.get('devcode')
const userRepository = RepositoryFactory.get('users')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'

import router from '../js/router'

export const useOnboardingsStore = defineStore('onboardings', {
  state: () => ({
    loading: false,
    loadingContact: false,
    loadingRequested: false,
    user: null,
    activeTheme: 'light',
    otpVerified: false,
    joinRequested: false,
    salesContacted: false,
    loadingKYC: false,
    submitFormStatus: '',
    occupationList: [],
    submitFormErrors: [],
    kycPayload: {
      email: '',
      username: '',
      salutation: 'Mr.',
      firstName: '',
      middleName: '',
      lastName: '',
      maritalStatus: '',
      dob: '',

      dependentNo: '',
      countryOfContact: '',
      contactNo: '',
      countryOfBirth: '',

      streetOfResidence: '',
      cityOfResidence: '',
      postalCodeOfResidence: '',
      countryOfResidence: '',
      stateOfResidence: '',

      mailSameAsResidence: true,
      streetOfMail: '',
      cityOfMail: '',

      countryOfMail: '',
      stateOfMail: '',
      postalCodeOfMail: '',

      citizenship: '',
      issuingCountry: '',

      issuedDocumentType: '',
      issuedDocumentNumber: '',

      issuingState: '',
      issuingRta: '',
      citizenMediCardColor: '',
      citizenMediCareRefNo: '',

      issuedDocumentHasExpiry: false,
      issuedDocumentExpiryDate: '',

      checkCitizenExpDate: false,
      citizenExpDate: '',
      openCitizenExpDate: false,

      employType: '',
      occupationList: null,
      occupation: '',
      employer: '',
      employerBusiness: '',

      empAddressSameAsResidence: true,
      employerStreet: '',
      employerCity: '',
      employerState: '',
      employerPostal: '',
      employerCountry: '',

      taxCountry: '',
      baseCurrency: 'EUR',

      netWorth: '',
      liquidNetWorth: '',
      annualNetIncome: '',

      sourceWealthType: '',
      customSourceOfIncome: '',
      usedForFunds: true,
      incomeSourcePercent: '100',

      investmentObjective: '',

      assetClass: 'STK',
      knowledgeLevel: '',
      tradesPerYear: '',
      yearsTrading: '',
      signature: '',
      agreed: false
    }
  }),
  actions: {
    resetPhoneForm() {
      this.salesContacted = false
    },
    resetJoinRequest() {
      this.joinRequested = false
    },
    async iframe(payload) {
      this.loading = true
      const response = await devcodeID.devcode(payload)
      if (response.status === 200 && response.data) {
        this.loading = false
        return response.data
      }
    },
    async requestJoinExchange(payload) {
      try {
        this.loadingRequested = true
        const response = await userRepository.requestJoinExchange(payload)
        if (response.status === 200 && response.data) {
          this.joinRequested = true
          this.loadingRequested = false
          return response.data
        }
      } catch (e) {
        this.loadingRequested = false
        useSnackbarStore().snack({
          text: i18n.global.t('Could not send a join request, please try again later'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async requestJoin(payload) {
      try {
        this.loadingRequested = true
        const response = await userRepository.requestJoin(payload)
        if (response.status === 200) {
          this.joinRequested = true
          this.loadingRequested = false
          return response.data
        }
      } catch (e) {
        this.loadingRequested = false
        useSnackbarStore().snack({
          text: i18n.global.t('Could not send a join request, please try again later'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async iframeResponse(payload) {
      this.loading = true
      const response = await devcodeID.auth(payload)
      this.loading = false
      return response
    },
    async otpVerification(payload) {
      try {
        this.loading = true
        const response = await userRepository.verifyOTP(payload)
        console.log(response)
        if (response.status === 200) {
          this.loading = false
          if (response.data.status === 'success') {
            this.otpVerified = true
            return 'success'
          } else {
            return response.data
          }
        }
      } catch (e) {
        this.loading = false
        useSnackbarStore().snack({
          text: i18n.global.t('OTP does not match. Try'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async sendOTP(payload) {
      try {
        this.loading = true
        const response = await userRepository.sendOTP(payload)
        if (response.status === 200) {
          this.loading = false
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loading = false

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async contactSalesExchanges(payload) {
      try {
        this.loadingContact = true
        const response = await userRepository.contactSalesExchange(payload)
        if (response.status === 200) {
          this.salesContacted = true
          this.loadingContact = false
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loading = false

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async contactSales(payload) {
      try {
        this.loadingContact = true
        const response = await userRepository.contactSales(payload)
        if (response.status === 200) {
          this.salesContacted = true
          this.loadingContact = false
          useSnackbarStore().snack({
            text: i18n.global.t('Code generated successfully. please check out email for the code'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loading = false

        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async sendPDF(payload) {
      try {
        this.loading = true

        const response = await userRepository.sendPDF(payload)
        if (response.status === 200) {
          this.loading = false
          return 'success'
        } else {
          console.log(response)
        }
      } catch (e) {
        this.loading = false
        if (Number(e.response.status) === 422) {
          useSnackbarStore().snack({
            text: i18n.global.t('Email does not exists in our record. please enter correct email'),
            type: 'error',
            show: true,
            autoclose: false
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },
    async storeUserDetails() {
      try {
        this.loadingStoreUserDetails = true

        const payload = {
          ...this.kycPayload,
          occupationList: null,
          occupation: this.kycPayload.occupation.name,
          employerBusiness: this.kycPayload.employerBusiness.name
        }

        const response = await userRepository.storeUserDetails(payload)
        if (response.status === 200) {
          this.loadingStoreUserDetails = false
          return response.data
        } else {
          console.log(response)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
        this.loadingStoreUserDetails = false
      }
    },
    async submitForm() {
      try {
        console.log('this.kycPayload', this.kycPayload)
        const payload = {
          ...this.kycPayload,
          occupationList: null,
          occupation: this.kycPayload.occupation.name,
          employerBusiness: this.kycPayload.employerBusiness.name
        }
        console.log('payload', payload)

        this.loadingKYC = true

        const response = await userRepository.submitKYCForm(payload)
        this.loadingKYC = false
        if (response.status === 200 && response.data) {
          this.submitFormStatus = response.data.status
          if (this.submitFormStatus === 'Already processed') {
            setTimeout(function () {
              router.push({
                name: 'myEucapsSettings'
              })
            }, 5000)
          } else if (this.submitFormStatus === 'Error') {
            if (typeof response.data.errors === 'string') {
              if (this.submitFormErrors.length > 0) {
                this.submitFormErrors = []
              }

              let errorString = response.data.errors
              if (errorString.includes('KnowledgeLevel')) {
                errorString = i18n.global.t('Your Investment Knowledge is insufficient for Stock Trading')
              } else if (errorString.includes('Investment Experience')) {
                errorString = i18n.global.t('Your Trading Experience is insufficient for Stock Trading')
              }

              this.submitFormErrors.push(errorString)
            } else if (typeof response.data.errors.Error === 'string') {
              if (this.submitFormErrors.length > 0) {
                this.submitFormErrors = []
              }
              let errorString = response.data.errors.Error
              if (errorString.includes('KnowledgeLevel')) {
                errorString = i18n.global.t('Your Investment Knowledge is insufficient for Stock Trading')
              } else if (errorString.includes('Investment Experience')) {
                errorString = i18n.global.t('Your Trading Experience is insufficient for Stock Trading')
              }

              this.submitFormErrors.push(errorString)
            } else if (response.data.errors && response.data.errors.length > 0) {
              for (let i = 0; i < response.data.errors.length; i++) {
                let errorString = JSON.stringify(response.data.errors[i])

                if (errorString.includes('KnowledgeLevel')) {
                  errorString = i18n.global.t('Your Investment Knowledge is insufficient for Stock Trading')
                } else if (errorString.includes('Investment Experience')) {
                  errorString = i18n.global.t('Your Trading Experience is insufficient for Stock Trading')
                }
                this.submitFormErrors.push(errorString)
              }
            } else {
              this.submitFormErrors = response.data.errors.Error
            }
            console.log('this.submitFormErrors', this.submitFormErrors)
          }
        } else {
          this.submitFormStatus = 'E'
        }
        console.log(response)
      } catch (e) {
        console.error(e)
        this.loadingKYC = false

        this.submitFormStatus = 'E'
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async sendExhangePDF(payload) {
      try {
        this.loading = true

        const response = await userRepository.sendPDF(payload)
        if (response.status === 200) {
          this.loading = false
          return 'success'
        } else {
          console.log(response)
        }
      } catch (e) {
        this.loading = false
        if (Number(e.response.status) === 422) {
          useSnackbarStore().snack({
            text: i18n.global.t('Email does not exists in our record. please enter correct email'),
            type: 'error',
            show: true,
            autoclose: false
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    }
  }
})
