import { RepositoryFactory } from '@/api/repositoryFactory'
import mentions from '@/js/helpers/mentions'
import router from '@/js/router'
import socket from '@/js/socket'
import i18n from '../js/language'
const repository = RepositoryFactory.get('chats')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useCompaniesStore } from './companies'
import { useUsersStore } from './users'

export const useChatsStore = defineStore('chats', {
  state: () => ({
    chatList: [],
    alert: {
      title: '',
      create: false,
      body: '',
      type: '',
      showConfirmButton: false
    },
    chatTitle: 'General Chats',
    activeUsers: [],
    loading: false,
    newLoading: false,
    loadingNewMessages: false,
    loadingMessages: false,
    loadingCompanyChat: true,
    selectedChatId: null,
    selectedRoom: 'general',
    selectedChatUuid: null,
    sendingMessages: false,
    nextPageUrl: null,
    loadingChat: false,
    list: [],
    originalMessages: [],
    seenBy: null,
    filter: {
      byCompany: null
    },
    loadingChatMenu: false,
    editMessageId: null
  }),
  getters: {
    unreadMessages() {
      if (!this.originalMessages) return 0
      let unreadMessageArray = []
      Object.keys(this.originalMessages).forEach((chat) => {
        let unreadMessage = 0
        if (this.originalMessages[chat].length > 0) {
          this.originalMessages[chat].forEach((message) => {
            const data = message.seen_by.find(
              (user) => Number(user.id) === Number(useUsersStore().user.profile.id) && Number(user.pivot.seen) === 0
            )
            if (data) {
              unreadMessage++
            }
          })
        }
        unreadMessageArray[chat] = unreadMessage
      })
      return unreadMessageArray
    },
    selectedChat: (state) => {
      return state.list.find((chat) => chat.id === state.selectedChatId)
    },
    selectedChatUser: (state) => {
      let chat = state.list.find((chat) => chat.id === state.selectedChatId)
      return chat.stock_users
    },
    messages(state) {
      if (!state.originalMessages[state.selectedChatId]) {
        return []
      }
      return state.originalMessages[state.selectedChatId].filter((message) => message.room === state.selectedRoom)
    },
    generalChats: (state) => {
      return state.list.filter((chat) => chat.company === null)
    },
    hasGeneralChats: (state) => state.generalChats.length > 0,
    hasCompanyChats: (state) => state.companyChats.length > 0,
    companyChats: (state) => {
      if (state.filter.byCompany === null) return []
      return state.list.filter((chat) => {
        if (chat.company === null) return false
        return chat.company.id === state.filter.byCompany
      })
    }
  },
  actions: {
    pushMessage(payload) {
      payload.filter((chatMessage) => {
        if (chatMessage.message.mentions.length > 0) {
          if (chatMessage.chatable.message && chatMessage.chatable.message.match(mentions.MATCHING)) {
            chatMessage.chatable.message = mentions.parse(
              chatMessage.chatable.message,
              chatMessage.message.mentions,
              'chat'
            )
          }
        }
        if (chatMessage.chatable.message && chatMessage.chatable.message.endsWith("<p><br></p>")) {
          while (chatMessage.chatable.message.endsWith("<p><br></p>")) {
            chatMessage.chatable.message = chatMessage.chatable.message.substring(0, chatMessage.chatable.message.lastIndexOf("<p><br></p>"));
          }
        }
      })

      if (!this.originalMessages || !this.originalMessages[this.selectedChatId]) {
        this.originalMessages = {
          ...this.originalMessages,
          [this.selectedChatId]: payload
        }
      } else {
        payload.find((response) => {
          const findIndex = this.originalMessages[this.selectedChatId].findIndex(
            (message) => message.id === response.id
          )
          if (findIndex > -1) {
            this.originalMessages[this.selectedChatId][findIndex] = response
          } else {
            this.originalMessages[this.selectedChatId].push(response)
          }
        })
      }

      this.originalMessages = { ...this.originalMessages }
    },
    unshiftMessage(payload, reset = false) {
      if (payload.length === 0) {
        return
      }
      const chatId = payload[0].chat_id
      payload.map((chatMessage) => {
        if (chatMessage.chatable.message && chatMessage.chatable.message.match(mentions.MATCHING)) {
          chatMessage.chatable.message = mentions.parse(
            chatMessage.chatable.message,
            chatMessage.message.mentions,
            'chat'
          )
        }
        if (chatMessage.chatable.message && chatMessage.chatable.message.endsWith("<p><br></p>")) {
          while (chatMessage.chatable.message.endsWith("<p><br></p>")) {
            chatMessage.chatable.message = chatMessage.chatable.message.substring(0, chatMessage.chatable.message.lastIndexOf("<p><br></p>"));
          }
        }
      })

      let chat = this.list.find((chat) => chat.id === chatId)
      if (chat) {
        chat.updated_at = payload[0].updated_at
      }

      if (!this.originalMessages || !this.originalMessages[chatId] || reset) {
        this.originalMessages = { ...this.originalMessages, [chatId]: payload }
      } else {
        payload.find((response) => {
          const findIndex = this.originalMessages[chatId].findIndex((message) => message.id === response.id)
          if (findIndex > -1) {
            let chat = this.originalMessages[chatId]
            let message = chat[findIndex]
            message = response
            chat[findIndex] = message
            this.originalMessages = { ...this.originalMessages, [chatId]: chat }
            // this.originalMessages[chatId][findIndex] = response
          } else {
            this.originalMessages[chatId].unshift(response)
          }
        })
      }

      this.originalMessages = { ...this.originalMessages }
    },
    unshiftChatListMessages(messages) {
      Object.keys(messages).forEach((chatId) => {
        let payload = messages[chatId]
        if (payload.length === 0) {
          return
        }

        payload.map((chatMessage) => {
          if (chatMessage.chatable.message && chatMessage.chatable.message.match(mentions.MATCHING)) {
            chatMessage.chatable.message = mentions.parse(
              chatMessage.chatable.message,
              chatMessage.message.mentions,
              'chat'
            )
          }
          if (chatMessage.chatable.message && chatMessage.chatable.message.endsWith("<p><br></p>")) {
            while (chatMessage.chatable.message.endsWith("<p><br></p>")) {
              chatMessage.chatable.message = chatMessage.chatable.message.substring(0, chatMessage.chatable.message.lastIndexOf("<p><br></p>"));
            }
          }
        })

        let chatData = this.list.find((chat) => chat.id === chatId)
        if (chatData) {
          chatData.updated_at = payload[0].updated_at
        }

        if (!this.originalMessages || !this.originalMessages[chatId]) {
          this.originalMessages = { ...this.originalMessages, [chatId]: payload }
        } else {
          payload.find((response) => {
            const findIndex = this.originalMessages[chatId].findIndex((message) => message.id === response.id)
            if (findIndex > -1) {
              let chat = this.originalMessages[chatId]
              let message = chat[findIndex]
              message = response
              chat[findIndex] = message
              this.originalMessages = { ...this.originalMessages, [chatId]: chat }
              // this.originalMessages[chatId][findIndex] = response
            } else {
              this.originalMessages[chatId].unshift(response)
            }
          })
        }
        this.originalMessages = { ...this.originalMessages }
      })
    },
    setChatList(chatList) {
      this.list = [...chatList]
    },
    setEditMessageId(id) {
      this.editMessageId = id
    },
    async fetch() {
      try {
        if (this.list.length > 0) {
          this.newLoading = true
        } else {
          this.loading = true
        }
        const response = await repository.list()
        this.loading = false
        this.newLoading = false
        if (response.status === 200 && response.data) {
          this.setChatList(response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchChatListMessages() {
      const response = await repository.fetchChatListMessages()
      if (response.status === 200 && response.data) {
        this.unshiftChatListMessages(response.data)
      }
    },
    async joinChats() {
      await this.fetch()
      if (this.list.length > 0) {
        const email = useUsersStore().user.email
        const chatList = []
        this.list.forEach((chat) => {
          // DISABLE COMPANY CHATS FOR NOW
          if (chat.company) {
            return
          }

          chatList.push(chat.id)

          socket.emit('join-room', chat.id.toString(), email, (data) => {
            if (data !== null) {
              this.setSeenBy(data)
            }
          })

          // let rooms = ['-general']

          // if (chat.company) {
          //   rooms.push('-stock')
          //   rooms.push('-analysis')
          //   rooms.push('-offtopic')
          // }
          // rooms.map((room) => {
          //   console.log('JOIN ROOM ' + chat.id.toString() + room)
          //   socket.emit('join-room', chat.id.toString() + room, email, (data) => {
          //     // socket.emit('join-room', chat.id.toString(), email, (data) => {
          //     if (data !== null) {
          //       this.setSeenBy(data)
          //     }
          //   })
          // })
        })
        if (chatList.length > 0) {
          this.fetchChatListMessages()
        }
      }
    },
    async scroll() {
      this.loadingNewMessages = true
      const response = await repository.scroll(this.nextPageUrl)
      this.loadingNewMessages = false
      if (response.status === 200 && response.data.data) {
        await this.pushMessage(response.data.data)
        this.nextPageUrl = response.data.next_page_url
      }
    },
    async joinCompanyChat(id) {
      try {
        // console.log('JOIN COMPANY CHAT')

        const response = await repository.joinCompanyChat(id)
        // console.log('JOIN COMPANY CHAT 2')
        if (response.status === 200) {
          let rooms = ['-general']

          rooms.push('-stock')
          rooms.push('-analysis')
          rooms.push('-offtopic')
          rooms.map((room) => {
            socket.emit('join-room', response.data.id.toString() + room, useUsersStore().user.email, (data) => {
              if (data !== null) {
                this.setSeenBy(data)
              }
            })
          })
        }
      } catch (e) {
        console.log('Error occured: ' + e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    updatemessageSeen(data) {
      if (data.length > 0) {
        data.forEach((messageid) => {
          this.originalMessages[this.selectedChatId].find((message) => {
            if (message.id === messageid) {
              message.seen_by.find((user) => {
                if (user.id === useUsersStore().user.profile.id) {
                  user.pivot.seen = 1
                }
              })
            }
          })
        })
        this.originalMessages = { ...this.originalMessages }
      }
      this.selectedChatUuid = null
      this.selectedChatId = null
    },
    async messagesSeen(data) {
      try {
        await repository.messagesSeen(this.selectedChatUuid, {
          chat_messages: data
        })
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchMessages() {
      try {
        if (this.originalMessages[this.selectedChatId] === undefined) {
          this.loadingMessages = true
        }
        const response = await repository.fetchMessages(this.selectedChatUuid)
        this.loadingMessages = false
        if (response.status === 200 && response.data && response.data.data) {
          this.unshiftMessage(response.data.data, true)
          this.nextPageUrl = response.data.next_page_url

          if (response.data.data && response.data.data.length > 0) {
            socket.emit('message-seen', {
              userId: useUsersStore().user.profile.id,
              chatId: this.selectedChatId,
              room: this.selectedRoom,
              messageId: response.data.data[0].id
            })
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setActiveUsers(payload) {
      this.activeUsers = payload
    },
    userOffline(payload) {
      const index = this.activeUsers.findIndexOf((data) => data === payload)
      this.activeUsers.splice(index, 1)
    },
    async one(chatUuid) {
      try {
        const response = await repository.singleChat(chatUuid)
        if (response.status === 200 && response.data) {
          this.setChatList([response.data])
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setSeenBy(payload) {
      this.seenBy = { ...this.seenBy, [this.selectedChatId ? this.selectedChatId : Number(payload[0].chatId)]: payload }
    },
    async like(payload) {
      try {
        const response = await repository.like(this.selectedChatUuid, payload.messageId, { reaction: payload.reaction })
        if (response.status === 200 && response.data) {
          this.pushMessage([response.data])
          socket.emit('resend-message', response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    resetAlert() {
      this.alert = {
        title: '',
        create: false,
        body: '',
        type: '',
        showConfirmButton: false
      }
    },
    setAlert(payload) {
      this.alert.title = payload.title
      this.alert.body = payload.body
      this.alert.create = payload.create
      this.alert.type = payload.type
      this.alert.showConfirmButton = payload.showConfirmButton
    },
    joinChat(data) {
      this.setChatList([data])
      socket.emit('join-room', data.id.toString(), useUsersStore().user.email, (data) => {
        if (data !== null) {
          this.setSeenBy(data)
        }
      })  
    },
    async create(payload) {
      try {
        this.newLoading = true
        const response = await repository.createChat(payload)
        this.newLoading = false

        if (response.status === 201 && response.data) {
          this.setChatList([response.data])
          this.loadingChat = false
          if (this.activeUsers.includes(payload.stock_users[0])) {
            socket.emit('chat-created', response.data, payload.stock_users) 
          }
          socket.emit('join-room', response.data.id.toString(), useUsersStore().user.email, (data) => {
            if (data !== null) {
              this.setSeenBy(data)
            }
          })
          router.push({ name: 'chat', params: { id: response.data.uuid } })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            text: 'chat not created. please contact support.',
            show: true,
            autoclose: true
          })  
        }
      } catch (e) {
        this.newLoading = false
        this.loadingChat = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async selectChat(uuid) {
      if (this.list.length === 0) {
        await this.one(uuid)
      }

      const chat = this.list.find((chats) => chats.uuid === uuid)
      if (chat) {
        this.selectedChatId = chat.id
        this.selectedChatUuid = chat.uuid
      } else {
        router.push({ name: 'chats' })
      }
    },
    async setSelectedRoom(room) {
      this.selectedRoom = room
    },
    async setSelectedCompanyChat() {
      this.loadingCompanyChat = true
      const chat = this.list.find((chats) => chats.company_id === useCompaniesStore().selectedCompanyId)
      if (chat) {
        this.selectedChatUuid = chat.uuid
        this.selectedChatId = chat.id
        this.loadingCompanyChat = false
      } else {
        await this.fetchCompanyChat()
        this.loadingCompanyChat = false
      }
    },
    async fetchCompanyChat() {
      try {
        const response = await repository.fetchCompanyChat(useCompaniesStore().selectedCompanyId)
        if (response.status === 200 && response.data) {
          this.setChatList([response.data])
          this.selectedChatId = response.data.id
          this.selectedChatUuid = response.data.uuid
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async startChat(payload) {
      try {
        this.loadingChat = true
        let findChat = null
        if (this.list.length > 0) {
          findChat = this.list.find((chat) => {
            if (chat.stock_users.length !== 2) return null

            return chat.stock_users.find((user) => user.id === payload.id)
          })
        }
        if (findChat === null) {
          const users = {
            stock_users: []
          }
          users.stock_users.push(payload.id)
          await this.create(users)
        } else {
          this.loadingChat = false
          router.push({ name: 'chat', params: { id: findChat.id } })
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    setMessages(payload) {
      this.pushMessage(payload)
      if (payload.messages.length > 0) {
        socket.emit('message-seen', {
          userId: useUsersStore().user.profile.id,
          chatId: this.selectedChatId,
          room: this.selectedRoom,
          messageId: payload.messages[payload.messages.length - 1].id
        })
      }
    },
    async addMember(user) {
      try {
        const chat = this.list.find((chat) => chat.id === this.selectedChatId)

        let isUserAlreadyExist = false
        for (let i = 0; i < chat.stock_users.length; i++) {
          const chatUser = chat.stock_users[i]
          if (chatUser.id === user.id) {
            isUserAlreadyExist = true
            break
          }
        }
        if (isUserAlreadyExist) {
          useSnackbarStore().snack({
            text: i18n.global.t('Member already added.'),
            type: 'info',
            show: true,
            autoclose: true
          })
        } else {
          chat.stock_users.push({
            pivot: {
              chat_id: this.selectedChatId,
              stock_user_id: user.id
            },
            companies_count: user.companies_count,
            created_at: user.created_at,
            username: user.username,
            full_name: user.full_name,
            id: user.id,
            image: user.image,
            posts_count: user.posts_count,
            reputation: user.reputation
          })
          const payload = {
            stock_users: []
          }
          payload.stock_users.push(user.id)
          const response = await repository.addMember(this.selectedChatUuid, payload)
          if (response.status === 204) {
            useSnackbarStore().snack({
              text: i18n.global.t('Member added successfully'),
              type: 'success',
              show: true,
              autoclose: true
            })
            // commit('SET_ALERT', { create: true, title: 'Success!', body: 'Member added successfully.' })
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async removeMember(userIds) {
      try {
        const chat = this.list.find((chat) => chat.id === this.selectedChatId)
        userIds.forEach((userId) => {
          let chatUserIndex = chat.stock_users.findIndex((chatUser) => {
            return chatUser.id === parseInt(userId)
          })
          chat.stock_users.splice(chatUserIndex, 1)
        })

        const response = await repository.removeMember(this.selectedChatUuid, {
          stock_users: userIds
        })
        if (response.status === 204) {
          useSnackbarStore().snack({
            text: i18n.global.t('Member removed successfully'),
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        console.log(e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async sendMessage(payload) {
      try {
        this.sendingMessages = true
        if (payload.unreadMessages.length > 0) {
          payload.unreadMessages.forEach((messageid) => {
            this.originalMessages[this.selectedChatId].find((message) => {
              if (message.id === messageid) {
                message.seen_by.find((user) => {
                  if (user.id === useUsersStore().user.profile.id) {
                    user.pivot.seen = 1
                  }
                })
              }
            })
          })
          this.originalMessages = { ...this.originalMessages }
        }
        const response = await repository.saveMessage(this.selectedChatUuid, this.selectedRoom, payload.formData)
        this.sendingMessages = false
        if (response.status === 200 && response.data) {
          this.unshiftMessage([response.data])
          socket.emit('send-message', response.data)
        }
      } catch (e) {
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async editMessage(payload) {
      try {
        const response = await repository.editMessage(this.selectedChatUuid, payload.messageId, payload.formData)
        this.editMessageId = null
        if (response.status === 200 && response.data) {
          this.unshiftMessage([response.data])
          socket.emit('resend-message', response.data)
        }
      } catch (e) {
        this.editMessageId = null
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    receiveMessage(payload, showMessage = true) {
      this.unshiftMessage([payload])
      if (payload && showMessage && (!this.selectedChat || this.selectedChat.uuid !== payload.chat.uuid)) {
        useSnackbarStore().snack({
          type: 'info',
          text: 'new message received from ' + payload.stock_user.username,
          show: true,
          autoclose: true
        })  
      }
    },
    async report(payload) {
      try {
        this.loadingChatMenu = true
        const response = await repository.report(payload)

        this.loadingChatMenu = false
        if (response.status === 200) {
          useSnackbarStore().snack({
            text: 'Message reported successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            text: 'Message already reported',
            type: 'info',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loadingChatMenu = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })

        return {
          status: 'false'
        }
      }
    },
    async messageDeleted(payload) {
      this.originalMessages[payload.chatId].splice(
        this.originalMessages[payload.chatId].findIndex((message) => message.id === payload.messageId),
        1
      )
    },
    async deleteMessage(payload) {
      try {
        this.loadingChatMenu = true
        const response = await repository.deleteMessage(this.selectedChatUuid, payload)
        this.loadingChatMenu = false
        if (response.status === 204) {
          socket.emit('delete-message', {
            chatId: this.selectedChatId,
            room: this.selectedRoom,
            messageId: payload
          })
          let responsePayload = {
            chatId: this.selectedChatId,
            messageId: payload
          }

          this.originalMessages[responsePayload.chatId].splice(
            this.originalMessages[responsePayload.chatId].findIndex(
              (message) => message.id === responsePayload.messageId
            ),
            1
          )

          useSnackbarStore().snack({
            text: 'Message deleted successfully',
            type: 'success',
            show: true,
            autoclose: true
          })
        }
      } catch (e) {
        this.loadingChatMenu = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    reset() {
      this.chatList = [...[]]
      this.list = [...[]]
      this.originalMessages = [...[]]
      this.activeUsers = [...[]]
    }
  }
})
