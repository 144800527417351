<template>
  <div
    class="flex-shrink-0 relative"
    :class="[margin,size, (size.indexOf('w-4 h-4') > -1 ? 'small-flag' : '')]"
  >
    <div class="absolute rounded-full inset-2">
      <CountryFlag
        :country="countryCode"
        gradient="top-down"
        :class="size =='w-2 h-2' ? 'vue-flag2 rounded-full' : 'vue-flag rounded-full' "
        :has-border="false"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CountryFlagInternal',
  props: {
    company: {
      type: Object,
      default: () => { }
    },
    tag: {
      type: String,
      default: 'country'
    },
    size: {
      type: String,
      default: 'w-6 h-6'
    },
    margin: {
      type: String,
      default: 'mr-2'
    }
  },
  computed: {
    countryCode () {
      if (this.company[this.tag] === 'GB') return 'GB'
      else return this.company[this.tag]
    }
  }
}
</script>

<style lang="scss">
//  .vue-flag.flag.size-m {
//     width: 100% !important;
//     height: 100% !important;
//  }
.vue-flag {
  display: block !important;
  margin-right: 10px;
  width: 39px !important;
  position: relative !important;
  height: 39px !important;
  top: -18px !important;
  left: -44% !important;
}

.vue-flag2 {
  display: block !important;
  margin-right: 10px !important;
  position: absolute !important;
  width: 38px !important;
  height: 38px !important;
  top: -18px !important;
  left: -05px !important;
}

.small-flag {
  .vue-flag {
    left: -16%;
  }
}
</style>
