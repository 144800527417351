<template>
  <div>
    <ModalContainer
      v-if="showShare"
      popup
      scrollable
      modal-container-background="h-auto"
      modal-content-class="flex items-center"
      padding="px-4 md:px-0"
      only-back-with-toggle
      @toggle="$emit('shared')"
      @on-back-button="$emit('shared')"
    >
      <div class="text-center bg-white dark:bg-custom-purple-400 rounded-xl w-full">
        <div
          class="border-b border-black dark:border-custom-purple-300 text-lg cursor-pointer flex text-center items-center justify-center font-bold w-full py-4"
          @click="copyTestingCode"
        >
          <Icon
            name="link"
            class="mr-1 text-2xl"
          />Copy link
        </div>
        <div class="w-full flex justify-between">
          <SocialShareButton
            network="facebook"
            :url="url"
            :title="title"
            :description="description"
            class="flex py-4 items-center border-r border-black dark:border-custom-purple-300 justify-center w-2/6"
            @clicked="$emit('shared')"
          />
          <SocialShareButton
            network="twitter"
            :url="url"
            :title="title"
            :description="description"
            class="flex py-4 items-center border-r border-black dark:border-custom-purple-300 justify-center w-2/6"
            @clicked="$emit('shared')"
          />
          <SocialShareButton
            network="linkedin"
            :url="url"
            :title="title"
            :description="description"
            class="flex py-4 items-center justify-center w-2/6"
            @clicked="$emit('shared')"
          />
        </div>
        <input
          id="testing-code"
          type="hidden"
          :value="url"
        >
      </div>
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from '@/modules/modal'
import { useSnackbarStore } from '@/stores/snackbar'

export default {
  name: 'ShareWithSocialMedia',
  components: {
    ModalContainer
  },
  props: {
    showShare: { type: Boolean, default: false },
    title: { type: String, default: null },
    description: { type: String, default: null },
    url: { type: String, default: null }
  },
  emits: ['shared'],
  setup () {
    const snackbarStore = useSnackbarStore()
    return { snackbarStore }
  },
  methods: {
    copyTestingCode () {
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        this.snackbarStore.snack({ text: `Link copied to clipboard ${msg}`, type: 'success', show: true, autoclose: true })
      } catch (e) {
        this.snackbarStore.snack({ text: 'Oops, unable to copy', type: 'error', e: e, show: true, autoclose: true })
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
      this.$emit('shared')
    }
  }
}
</script>
