<template>
  <div class="flex lg:flex-row flex-col h-full w-full">
    <SignupText
      class="lg:text-white"
      :user="userType"
      @redirect-home="redirectHome"
    />
    <SignupForm
      :username="username"
      :email="email"
      :already-registered="alreadyRegistered"
      :loading-registration="loadingRegistration"
      :password="password"
      :terms="terms"
      :country="country"
      :filter-country="countrySearch"
      :country-list="countryList"
      :show-options="showOptions"
      :show-email-verification="showEmailVerification"
      :loading="loading"
      :user="userType"
      :invalid-email="invalidEmail"
      @redirect-login="redirectLogin"
      @email="setEmail"
      @password="setPassword"
      @username="setUsername"
      @terms="terms= !terms"
      @show-terms="showTerms = !showTerms"
      @show-privacy="showPrivacy = !showPrivacy"
      @handle-country="handleCountry"
      @on-submit="addUser"
      @filter-countries="countrySearch = $event"
      @handle-country-click="showOptions = $event"
      @password-tab="passwordTab"
    >
      <template #contentSlot>
        <div class="text-custom-purple-500">
          <UserText
            v-if="userType === 'normal'"
          />
          <CompanyText
            v-else-if="userType === 'companyUser'"
          />
        </div>
      </template>
    </SignupForm>
    <ModalContainer
      v-if="showPrivacy"
      :show="showPrivacy"
      full-size
      @toggle="showPrivacy = !showPrivacy"
      @on-back-button="showPrivacy = !showPrivacy"
    >
      <Privacy />
      <NeutralButton
        class="w-full text-md h-12 focus:outline-none"
        @click="showPrivacy = !showPrivacy"
      >
        {{ $t('Close') }}
      </NeutralButton>
    </ModalContainer>
    <ModalContainer
      v-if="showTerms"
      :show="showTerms"
      full-size
      @toggle="showTerms = !showTerms"
      @on-back-button="showTerms = !showTerms"
    >
      <Terms />
      <NeutralButton
        class="w-full text-md h-12 focus:outline-none"
        @click="showTerms = !showTerms"
      >
        {{ $t('Close') }}
      </NeutralButton>
    </ModalContainer>
  </div>
</template>

<script>
import SignupForm from './_components/SignupForm'
import SignupText from './_components/SignupText'
import UserText from './_components/UserText'
import CompanyText from '../loginSection/_components/CompanyText'
import ModalContainer from '@/modules/modal'
import Terms from '@/views/Terms'
import Privacy from '@/views/Privacy'
import { useUsersStore } from '../../stores/users'
import { useCountriesStore } from '../../stores/countries'

export default {
  name: 'SignupModule',
  components: {
    SignupForm,
    SignupText,
    UserText,
    CompanyText,
    ModalContainer,
    Terms,
    Privacy
  },
  setup() {
    const usersStore = useUsersStore()
    const countriesStore = useCountriesStore()

  return { usersStore, countriesStore }
  },
  data () {
    return {
      username: '',
      email: '',
      password: '',
      terms: false,
      country: null,
      userType: 'normal',
      countrySearch: '',
      showOptions: false,
      showPrivacy: false,
      showTerms: false,
      loading: true,
      invalidEmail: false
    }
  },
  computed: {
    alreadyRegistered () {
      return this.usersStore.alreadyRegistered
    },
    loadingRegistration () {
      return this.usersStore.loadingRegistration
    },
    countries () {
      return this.countriesStore.countries
    },
    user () {
      return this.usersStore.user
    },
    countryList () {
      return this.countries.filter(country => country.name.toLowerCase().startsWith(this.countrySearch.toLowerCase())).map(country => {
        return {
          id: country.id,
          code: country.code,
          name: country.name
        }
      })
    },
    showEmailVerification () {
      return this.user && this.user.profile && this.user.profile_type !== 'App\\Models\\GuestUser' && !this.user.email_verified_at
    }
  },
  async mounted () {
    await this.countriesStore.fetch()
    if (this.user && this.user.profile && this.user.profile_type !== 'App\\Models\\GuestUser' && this.user.email_verified_at) {
      const name = (this.userType === 'companyUser') ? 'onboarding-company' : 'onboarding'
      this.$router.push({
        name
      })
    }

    this.loading = false
  },
  methods: {
    setUsername (event) {
      this.username = event.target.value
      this.resetAlreadyRegistered()
    },
    setEmail (event) {
      this.invalidEmail = false
      this.email = event.target.value
      this.resetAlreadyRegistered()
    },
    setPassword (event) {
      this.password = event.target.value
      this.resetAlreadyRegistered()
    },
    resetAlreadyRegistered () {
      if (this.alreadyRegistered) {
        this.usersStore.resetRegisterMessage()
      }
    },
    passwordTab () {
      // console.log('PASSWORD TAB')
    },
    handleCountry (value) {
      this.country = value
      this.showOptions = !this.showOptions
    },
    validateEmail(email) {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      return pattern.test(email)

    },
    async addUser () {
      if(this.validateEmail(this.email)) {
        this.invalidEmail = false
        await this.usersStore.register( {
          username: this.username,
          email: this.email,
          password: this.password,
          country: this.country.id,
          userType: this.userType
        })
      } else this.invalidEmail = true

      // if (!this.alreadyRegistered) this.showEmailVerification = true
      // this.$router.push(`/onboarding?type=${this.userType}`)
    },
    redirectLogin () {
      this.$router.push({ name: 'login' })
    },
    redirectHome () {
      this.$router.push('/')
    }
  }
}
</script>
