import { get, post, destroy, put } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}`

const getBusinessDetailsList = async () => get(`${url}/business-details`)
const getBusinessGrowthDetail = async (id) => get(`${url}/${id}/business-details/growth`)
const newDocumentListFile = async (businessDetailId, data) => post(`${url}/business-details/${businessDetailId}/document-list`, data)
const deleteDocumentListFile = async (businessDetailId, id) => destroy(`${url}/business-details/${businessDetailId}/document-list/${id}`)
const deleteLink = async (businessDetailId, id) => destroy(`${url}/business-details/${businessDetailId}/links/${id}`)
const fetchBusinessOverview = async(id) => get(`${url}/companies/${id}/business-details/business-overview`)
const saveNewBusinessOverview = async (id, payload) => post(`${url}/companies/${id}/business-details/business-overview`, payload)
const fetchCompetitions = async(id) => get(`${url}/companies/${id}/business-details/business-competition`)
const addCompetitions = async(id, payload) => post(`${url}/companies/${id}/business-details/business-competition`, payload)
const updateCompetitions = async(id, payload) => put(`${url}/companies/${id}/business-details/business-competition/edit`, payload)
const deleteCompetitions = async(id, payload) => destroy(`${url}/companies/${id}/business-details/business-competition/delete/${payload.id}`)
const fetchBusinessComment = async(slug) => get(`${url}/companies/${slug}/business-details/business-comment`)
const addBusinessComment = async(slug, payload) => post(`${url}/companies/${slug}/business-details/business-comment`, payload)
const editBusinessComment = async(slug, payload) => put(`${url}/companies/${slug}/business-details/business-comment/edit`, payload)
const deleteBusinessComment = async(slug, payload) => destroy(`${url}/companies/${slug}/business-details/business-comment/delete/${payload.id}`)
const fetchElevatorPitch = async(id) => get(`${url}/companies/${id}/business-details/elevator-pitch`)
const addNewElevatorPitch = async(id, payload) => post(`${url}/companies/${id}/business-details/elevator-pitch`, payload)
const deleteElevatorPitch = async(id,) => destroy(`${url}/companies/${id}/business-details/elevator-pitch/delete`,)

// new achievements endpoint
const getCompanyAchievements = async (companySlug) => get(`${url}/companies/${companySlug}/achievements`)
const addCompanyAchievementsDescription = async (companySlug, data) => post(`${url}/companies/${companySlug}/achievements/add-description`, data)
const updateCompanyAchievementsDescription = async (companySlug, data) => post(`${url}/companies/${companySlug}/achievements/1/update-description`, data)
const addCompanyAchievement = async (companySlug, achievementDescriptionId, data) => post(`${url}/companies/${companySlug}/achievements/${achievementDescriptionId}/add-achievement`, data)
const updateCompanyAchievement = async (companySlug, achievementDescriptionId, achievementId, data) => post(`${url}/companies/${companySlug}/achievements/${achievementDescriptionId}/data/${achievementId}/update-achievement`, data)
const deleteAchievement = async (companySlug, achievementDescriptionId, achievementId) => destroy(`${url}/companies/${companySlug}/achievements/${achievementDescriptionId}/data/${achievementId}/delete-achievement`)

// certificates endpoints
const getCompanyCertificates = async (companySlug) => get(`${url}/companies/${companySlug}/certificates`)
const addCompanyCertificatesDescription = async (companySlug, data) => post(`${url}/companies/${companySlug}/certificates/add-description`, data)
const updateCompanyCertificatesDescription = async (companySlug, data) => post(`${url}/companies/${companySlug}/certificates/1/update-description`, data)
const addCompanyCertificate = async (companySlug, certificateDescriptionId, data) => post(`${url}/companies/${companySlug}/certificates/${certificateDescriptionId}/add-certificate`, data)
const updateCompanyCertificate = async (companySlug, certificateDescriptionId, certificateId, data) => put(`${url}/companies/${companySlug}/certificates/${certificateDescriptionId}/data/${certificateId}/update-certificate`, data)
const deleteCertificate = async (companySlug, certificateDescriptionId, certificateId) => destroy(`${url}/companies/${companySlug}/certificates/${certificateDescriptionId}/data/${certificateId}/delete-certificate`)

// Audio Reports endpoint
const fetchCompanyAudioReportsByYear = async (companySlug, data) => get(`${url}/companies/${companySlug}/audio-reports`, data)
const addCompanyAudioReport = async (companySlug, data) => post(`${url}/companies/${companySlug}/audio-reports/add`, data)
const updateCompanyAudioReport = async (companySlug, audioReportId, data) => put(`${url}/companies/${companySlug}/audio-reports/${audioReportId}/update`, data)
const deleteCompanyAudioReport = async (companySlug, audioReportId) => destroy(`${url}/companies/${companySlug}/audio-reports/${audioReportId}/delete`)
const getProductPortfolio = async (slug_url) => get(`${url}/companies/${slug_url}/business-details/product-portfolio`)
const editProductPortfolio = async(slug_url, data) => post(`${url}/companies/${slug_url}/business-details/product-portfolio`, data)
const getMarketAnalysis = async (slug_url) => get(`${url}/companies/${slug_url}/business-details/market-analysis`)
const editMarketAnalysis = async(slug_url, data) => post(`${url}/companies/${slug_url}/business-details/market-analysis`, data)
const getUsp = async (slug_url) => get(`${url}/companies/${slug_url}/business-details/usp`)
const saveNewUsp = async (slug_url, data) => post(`${url}/companies/${slug_url}/business-details/usp`, data)
const updateUsp = async (slug_url, uspId, data) => put(`${url}/companies/${slug_url}/business-details/usp/${uspId}`, data)
const deleteUsp = async (slug_url, uspId) => destroy(`${url}/companies/${slug_url}/business-details/usp/${uspId}`)
const getMarketPresence = async (slug_url) => get(`${url}/companies/${slug_url}/business-details/market-presence`)
const editMarketPresence = async(slug_url, data) => post(`${url}/companies/${slug_url}/business-details/market-presence`, data)
const fetchBusinessStatement = async(id) => get(`${url}/companies/${id}/business-details/business-statement`)
const addNewBusinessStatement = async(id, payload) => post(`${url}/companies/${id}/business-details/business-statement`, payload)

export default {
  getBusinessDetailsList,
  newDocumentListFile,
  deleteDocumentListFile,
  deleteLink,
  fetchBusinessOverview,
  saveNewBusinessOverview,
  fetchCompetitions,
  addCompetitions,
  updateCompetitions,
  deleteCompetitions,
  fetchBusinessComment,
  addBusinessComment,
  editBusinessComment,
  deleteBusinessComment,
  deleteElevatorPitch,
  fetchElevatorPitch,
  addNewElevatorPitch,
  getCompanyAchievements,
  addCompanyAchievementsDescription,
  updateCompanyAchievementsDescription,
  addCompanyAchievement,
  updateCompanyAchievement,
  deleteAchievement,
  getCompanyCertificates,
  addCompanyCertificatesDescription,
  updateCompanyCertificatesDescription,
  addCompanyCertificate,
  updateCompanyCertificate,
  deleteCertificate,
  fetchCompanyAudioReportsByYear,
  addCompanyAudioReport,
  updateCompanyAudioReport,
  deleteCompanyAudioReport,
  getProductPortfolio,
  editProductPortfolio,
  getMarketAnalysis,
  editMarketAnalysis,
  getUsp,
  saveNewUsp,
  updateUsp,
  deleteUsp,
  getMarketPresence,
  editMarketPresence,
  getBusinessGrowthDetail,
  fetchBusinessStatement,
  addNewBusinessStatement
}
