<template>
  <div
    class="flex flex-shrink-0 justify-center items-center relative"
    :class="[defaultClass,margins]"
  >
    <PremiumIcon
      v-if="usePremium"
      :is-premium="stockUser && stockUser.is_premium"
      :small="small"
      class="absolute bottom-0 -right-1 z-20"
    />
    <div
      v-if="avatar && avatar.image && avatar.image !== ''"
      class="bg-gray-400  bg-center bg-no-repeat bg-cover"
    
      :class="[ring ? 'ring-2 md:ring-4 ring-white dark:ring-custom-purple-400 dark:shadow-none' : '', ring && shadow? 'shadow' : '', rounded]"
      :style="style"
    />
    <AvatarInitials
      v-else
      :name="fullName"
      :class="defaultClass"
      :rounded="rounded"
      :size="size"
    />
  </div>
</template>

<script>

import { useUsersStore } from '@/stores/users'

export default {
  name: 'Avatar',
  props: {
    stockUser: {
      type: Object,
      default: () => {}
    },
    company: {
      type: Object,
      default: () => {}
    },
    rounded: {
      type: String,
      default: 'rounded-full'
    },
    usePremium: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: true
    },
    defaultClass: {
      type: String,
      default: 'w-10 h-10'
    },
    margins: {
      type: String,
      default: 'mr-2'
    },
    size: {
      type: Number,
      default: 40
    },
    ring: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const usersStore = useUsersStore()

    return {usersStore}
  },
  computed: {
    style () {
      const style = {
        display: 'flex',
        width: `${this.size}px`,
        height: `${this.size}px`,
        lineHeight: `${this.size + Math.floor(this.size)}px`,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        userSelect: 'none',
        backgroundImage: `url('${this.avatar.image}')`
      }
      
      Object.assign(style)
      return style
    },
    avatar () {
      if (this.stockUser) {
        return this.stockUser
      }

      if (this.company) {
        return this.company
      }

      return this.usersStore.user.profile
    },
    fullName () {
      if (!this.avatar) {
        return ''
      }

      if (this.company) {
        return this.avatar.name
      }

      if (this.avatar.user) {
        if (this.avatar.user.profile_type === 'App\\Models\\GuestUser') {
          return this.avatar.name
        }
      }

      return this.avatar.username
    }
  }
}
</script>

<style lang="scss">
  .star-margin {
    padding-top: 2px;
  }
</style>
