<template>
  <div
    class="flex flex-1 flex-col h-full bg-main-background dark:bg-custom-purple-500"
    :class="usersStore.activeTheme"
  >
    <NetworkContainer />
    <v-idle
      v-if="notIdle && tradingPages"
      :loop="true"
      hidden
      :events="['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll']"
      :duration="60 * usersStore.idleMinutes"
      :reminders="[60,120,180]"
      @remind="onRemind"
      @idle="onIdle"
    />
    <template v-if="usersStore.dsecret && usersStore.user">
      <!-- <LoggedInLayout v-else-if="userStatus === 'verifiedUser'" /> -->

      <FrontLayout v-if="isFrontLayout" />
      <SignInLayout v-else-if="isSignInLayout" />
      <SignupSection
        v-else-if="userStatus === 'emailVerificationNeeded'"
        :class="'flex flex-grow flex-shrink-0 flex-col bg-custom-purple-500'"
      />
      <LoggedInLayout v-else-if="userStatus === 'emailVerifiedUser'" />
      <StandardLayout v-else />
    </template>
    <div
      v-else
      class="flex justify-center mt-8 p-4"
    >
      <Loader />
    </div>
    <!-- <SnackBar /> -->
    <SnackBarNotification />
    <!-- <AchievementModal /> -->
    <UserBlockedModal />
    <WarningModal />
    <MaintenanceModal />
    <ForcedUpgradeModalContainer />
    <BecomePremiumModalContainer />
    <TradingEmailVerification
      v-if="usersStore.idleStatus && tradingPages && usersStore.showOtpVerificationModal"
    />
    <ModalContainer
      v-if="tradingPages && reminder.show"
      :padding="false"
      big-width
      :hide-close="true"
      :disable-close="true"
      :extra-class="'rounded-xl mx-4 md:mx-0'"
      popup
      @toggle="reminder.show = false"
      @on-back-button="reminder.show = false"
    >
      <Reminder
        v-if="tradingPages && reminder.show"
        :reminder="reminder"
        @logout="logout"
        @close="reminder.show=false"
      />
    </ModalContainer>
    <BecomeVerifiedUser
      v-if="usersStore.showVerificationModal"
      @toggle="closeVerificationModal"
    />
    <AudioPlayer
      v-if="audioStore.showPlayer"
      :source="audioStore.source"
      :thumbnail="audioStore.thumbnail"
      :title="audioStore.title"
      :subtitle="audioStore.subtitle"
      @close="audioClose"
    />
    <!-- <div
      v-if="webShareApiSupported"
      class="fixed z-60 cursor-pointer"
      :class="useMenu ? 'bottom-24 left-4' : 'bottom-32 left-10'"
    >
      <div
        class="flex relative justify-center items-center w-14 h-14 rounded-full bg-white hover:bg-custom-purple-400 hover:text-white cursor-pointer"
        @click="shareViaWebShare"
      >
        <Icon name="share-alt" />
      </div>

      <PremiumNotepadContainer :type="$route.name" />
    </div> -->
  </div>
</template>

<script>
import ModalContainer from '@/modules/modal'
import NetworkContainer from '@/modules/network'
import TradingEmailVerification from '@/modules/tradingEmailVerification'
import StandardLayout from '@/layout/StandardLayout'
import FrontLayout from '@/layout/FrontLayout'
import LoggedInLayout from '@/layout/LoggedInLayout'
import WarningModal from '@/modules/warning'
import MaintenanceModal from '@/modules/maintenanceModal'
import ForcedUpgradeModalContainer from '@/modules/forcedUpgradeModal'
import BecomePremiumModalContainer from '@/modules/becomePremiumModal'
// import PremiumNotepadContainer from '@/modules/premiumNotepad'
import SignInLayout from '@/layout/SignInLayout'
import SignupSection from '@/views/Signup'
// import StandardDevLayout from '@/layout/StandardDevLayout'
import AudioPlayer from '@/modules/audioPlayer'

import 'boxicons/css/boxicons.min.css'
// import EmailVerification from '@/views/EmailVerification'
import { App as CapacitorApp } from '@capacitor/app'
// import auth from '@/js/helpers/auth'

import { isMobile } from '@/js/helpers/mobile'
import usersMixin from '@/mixins/usersMixin'
import { useUsersStore } from '@/stores/users'
import { useAppsStore } from '@/stores/app'
import { useLanguagesStore } from '@/stores/languages'
import { useNotificationsStore } from '@/stores/notifications'
import { useSocketsStore } from '@/stores/sockets'
import { useSnackbarStore } from '@/stores/snackbar'
import { useAudioStore } from '@/stores/audio'


require('animate.css/animate.min.css')

export default {
  name: 'App',
  components: {
    TradingEmailVerification,
    // PremiumNotepadContainer,
    NetworkContainer,
    FrontLayout,
    StandardLayout,
    LoggedInLayout,
    WarningModal,
    MaintenanceModal,
    BecomePremiumModalContainer,
    ForcedUpgradeModalContainer,
    SignInLayout,
    SignupSection,
    ModalContainer,
    AudioPlayer
  },
  mixins: [usersMixin],
  async beforeRouteEnter () {
    await this.usersStore.fetchDSecret()
  },
  setup () {
    const usersStore = useUsersStore()
    const appsStore = useAppsStore()
    const languagesStore = useLanguagesStore()
    const notificationsStore = useNotificationsStore()
    const socketsStore = useSocketsStore()
    const snackbarStore = useSnackbarStore()
    const audioStore = useAudioStore()

    return { usersStore, appsStore, languagesStore, notificationsStore, socketsStore, snackbarStore, audioStore }
  },
  data () {
    return {
      reminder: {
        time: 1,
        show: false
      },
      checkUser: null
    }
  },
  metaInfo: {
    title: 'Eucaps',
    // titleTemplate: '%s | vue-meta Example App',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'An example Vue application with vue-meta.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  computed: {
    tradingPages () {
      if ( this.$route.matched && this.$route.matched[0]?.name === 'CompanyPage') return true
      else if (this.$route.name === 'myEucapsPortfolio') return true
      else return false
    },
    isInvertor () {
      return (this.usersStore?.profile?.is_premium && this.usersStore?.profile?.is_verified)
    },
    notIdle () {
      return this.isInvertor && !this.usersStore.idleStatus
    },
    isSignInLayout () {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout === 'SignInLayout'
      }
      return false
    },
    isFrontLayout () {
      if (this.$route.meta && this.$route.meta.layout) {
        return this.$route.meta.layout === 'FrontLayout'
      }

      return false
    },
    userStatus () {
      if (this.isGuest) return 'guest'
      else if (this.usersStore.user && this.usersStore.user.id && !this.usersStore.user.email_verified_at) return 'emailVerificationNeeded'
      else if (this.usersStore.user && this.usersStore.user.id && this.usersStore.user.email_verified_at) return 'emailVerifiedUser'
      else return false
    },
    showTodo () {
      return process.env.VUE_APP_SHOW_TODO !== 'false'
    }
  },
  created () {
    if (this.appsStoreisNative || isMobile()) {
      this.appsStore.useMenu(true)
    }
    // const userAgent = navigator.userAgent || navigator.vendor || window.opera
    // if (!this.appsStoreisNative) {
    //   if (/android/i.test(userAgent)) {
    //     window.location.href = 'https://play.google.com/store/apps/details?id=com.stock.market.discussion.axyer'
    //   } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //     window.location.href = 'https://apps.apple.com/us/app/axyer/id1598555414'
    //   }
    // }
    const self = this
    CapacitorApp.addListener('appUrlOpen', (event) => {
      let url = event.url.replace('axyer://', '/')
      if (self.$router.history.current.path.substring(1) !== url) {
        self.$router.push(url)
      }
    })
  },
  async mounted () {

    await this.usersStore.updateFCMToken()

    await this.appsStore.getLatestVersion()
    if (this.isInvertor) {
      await this.usersStore.fetchIdleStatus()
    }

    if (window.matchMedia('(prefers-color-scheme: dark)').matches && !localStorage.getItem('activeTheme')) {
      this.usersStore.setTheme('dark')
    }
    this.checkUser=setInterval(() =>{
      this.check()
    }, 60 * 1000);

  },
  async beforeCreate () {
    await this.usersStore.fetchDSecret()
    await this.usersStore.guest()
    this.languagesStore.init()
    this.usersStore.initTheme()
    this.notificationsStore.fetchSettings()
    clearInterval(this.checkUser)
  },
  unmounted () {
    this.socketsStore.disconnect()
    this.socketsStore.clean()
  },
  methods: {
    onIdle() {
      this.reminder.show = false
      if (this.notIdle) {
        this.usersStore.setUserIdle(this.usersStore.profile)
      }
    },
    check(){
    this.usersStore.checkUser()
    },
    logout() {
      this.usersStore.logout()
    },
    onRemind(time) {
      this.reminder.time = time/60
      this.reminder.show = true
      // this.snackbarStore.snack({ type: 'info', text: time, show: true, autoclose: true })
    },
    togglePremium () {
      this.usersStore.setIsPremium(!this.usersStore.isPremium)
    },
    closeVerificationModal () {
      this.usersStore.setShowVerificationModal(false)
    },
    audioClose(){
      this.audioStore.close()
    }
  }
}
</script>

<style lang="scss">
h1,
h2,
h3,
h4,
strong,
b {
  @apply font-header;
  font-weight: 700 !important;
}
input,
select,
textarea {
  font-size: 16px !important;
}
body {
  overflow-x: hidden;
}
</style>
