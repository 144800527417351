import i18n from '@/js/language/'
import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('trades')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useUsersStore } from './users'
import { useCompaniesStore } from './companies'
import { useJobsStore } from './jobs'

// import { useCompaniesStore } from "./companies"

export const useTradesStore = defineStore('trades', {
  state: () => ({
    loading: false,
    transactionsOrderLoading: false,
    transactionsscrollLoading: false,

    highestPrice: '',
    lowestPrice: '',
    stockPrice: '',
    snpClosePrice: '',
    snpEuroClosePrice: '',
    euroStockPrice: '',
    openPrice: '',

    changeInPrice: '',
    changeInPricePercent: '',
    courtage: '',
    tradingFees: '',
    stockTotalAmount: '',

    euroCourtage: '',
    euroTradingFees: '',
    euroStockTotalAmount: '',
    euroTotalAmount: '',
    euroOpenPrice: '',
    euroChangeInPricePercent: '',

    exchange: '',

    balance: '',
    balanceCurrency: '',
    totalAmount: '',

    progress: 0,
    orderStatus: 'inActive', //inActive or loading or success or failed

    transactions: [],
    transactionsLoading: false,
    nextTransactionPageUrl: '',
    scrollTransactionLoading: false,
    portfolioValue: '',
    availableFunds: '',
    availableFundsEuro: '',
    availableFundsCurrency: '',
    noOftrades: '',
    portfolioPerformance: '',
    holdings: '',
    stocksAvailable: '',

    stockCurrency: '',
    selectedCurrency: '',
    accountSummaryLoading: false,
    accountPerformanceLoading: false,

    fetchPriceInterval: null,
    priceErrorCount: 0,
    showTradingErrorModal: false,

    initialDetailsCall: false,
    initialPriceCall: false,

    pnl: '',

    receiptLoading: false
  }),
  getters: {
    tradeHoldings() {
      let tradeHoldings = []
      if (this.holdings && this.holdings !== '') {
        for (let i = 0; i < this.holdings.length; i++) {
          tradeHoldings[i] = this.holdings[i]
          tradeHoldings[i].today = Number(this.holdings[i]?.mktPrice - this.holdings[i]?.avgPrice).toFixed(3)

          tradeHoldings[i].acquisitionCostStock = Number(tradeHoldings[i].acquisitionCost / tradeHoldings[i].position)
          tradeHoldings[i].percentOfPortfolio = (Number(tradeHoldings[i].mktValue) / Number(this.portfolioValue)) * 100

          tradeHoldings[i].pnlPercent =
            (Number(tradeHoldings[i].unrealizedPnl) / Number(tradeHoldings[i].acquisitionCost)) * 100
        }

        return tradeHoldings
      } else {
        return []
      }
    },
    moneyInvested() {
      let moneyInvested = 0
      if (this.tradeHoldings.length > 0) {
        for (let i = 0; i < this.tradeHoldings.length; i++) {
          moneyInvested += this.tradeHoldings[i].acquisitionCost
        }
      }
      return moneyInvested
    }
  },
  actions: {
    setSelectedCurrency(payload, shouldNotify = false) {
      if (payload === 'LOCAL') {
        if (useUsersStore().user.profile.currency === this.selectedCurrency) {
          if (shouldNotify) {
            useSnackbarStore().snack({
              type: 'warning',
              text: 'Already selected local currency',
              show: true,
              autoclose: true
            })
          }
        } else if (useUsersStore().user.profile.currency === '') {
          useSnackbarStore().snack({
            type: 'warning',
            text: 'No base currency set, hence setting Local to EURO',
            show: true,
            autoclose: true
          })

          this.selectedCurrency = 'EUR'
        } else {
          this.selectedCurrency = useUsersStore().user.profile.currency
        }
      } else {
        this.selectedCurrency = payload
      }
    },
    async fetchStockDetails(isinId) {
      try {
        const response = await repository.fetchStockDetails({
          isin_id: isinId
        })
        if (response.status === 200 && response.data) {
          this.exchange = response.data.exchange
          this.stockCurrency = response.data.currency
        }
      } catch (e) {
        console.error(e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async fetchSnpMarketDetails(companyId) {
      useJobsStore().runEndpoint(
        'fetchSnpMarketDetails' + companyId,
        async () => {
          try {
            let currency = 'EUR'

            if (this.selectedCurrency) {
              currency = this.selectedCurrency
            }

            const response = await repository.fetchSnpMarketDetails({ companyId: companyId, currency: currency })
            if (response.status === 200 && response.data) {
              // if (response.data['closest_price']) {
              //   this.stockPrice = response.data['closest_price'];
              // }
              console.log('response.data', response.data)
              if (response.data['closest_price']) {
                this.snpClosePrice = response.data['closest_price'].toFixed(3)
              }

              if (response.data['eur_close_price']) {
                this.snpEuroClosePrice = response.data['eur_close_price'].toFixed(3)
              }

              if (response.data['highest_price']) {
                this.highestPrice = response.data['highest_price']
              }
              if (response.data['lowest_price']) {
                this.lowestPrice = response.data['lowest_price']
              }
              if (response.data['price_change_percentage']) {
                this.changeInPricePercent = response.data['price_change_percentage']
              }
              if (response.data['currency']) {
                this.stockCurrency = response.data['currency']
              }
            }
          } catch (e) {
            console.error(e)
          }
        },
        4000
      )
    },
    async fetchMarketDetails(isinId) {
      try {
        const response = await repository.fetchMarketDetails(isinId, {
          currency: this.stockCurrency
        })
        if (response.status === 200 && response.data) {
          if (response.data[0][31]) {
            this.stockPrice = response.data[0][31]
            // IF PRICE CONTAINS C OR H IN BEGINNING, REMOVE
            if (this.stockPrice.toString().includes('C') || this.stockPrice.toString().includes('H')) {
              this.stockPrice = this.stockPrice.slice(1)
            }
          }
          if (response.data[0]['euro_price']) {
            this.euroStockPrice = response.data[0]['euro_price']
          }
          if (response.data[0][70]) {
            this.highestPrice = response.data[0][70]
          }
          if (response.data[0][71]) {
            this.lowestPrice = response.data[0][71]
          }
          if (response.data[0][82]) {
            this.changeInPrice = response.data[0][82]
          }
          if (response.data[0][83]) {
            this.changeInPricePercent = response.data[0][83]
          }
          if (response.data[0][7295]) {
            this.openPrice = response.data[0][7295]
          }
          if (response.data[0]['euro_open_price']) {
            this.euroOpenPrice = response.data[0]['euro_open_price']
          }
          if (response.data[0]['euro_change_in_price_percent']) {
            this.euroChangeInPricePercent = response.data[0]['euro_change_in_price_percent']
          }

          //return response.data
        }
      } catch (e) {
        console.error(e)

        this.setPriceErrorCount(this.priceErrorCount + 1)

        console.log('this.priceErrorCount', this.priceErrorCount)
        if (this.priceErrorCount > 2) {
          this.stopPriceInterval()

          console.log('Interval stopped after 3 errors.')
          this.setShowTradingErrorModal(true)
          this.setPriceErrorCount(0)
        }
      }
    },
    resetStockPrice() {
      this.highestPrice = ''
      this.lowestPrice = ''
      this.euroStockPrice = ''
      this.stockPrice = ''
      this.snpClosePrice = ''
    },
    async accountInfo() {
      try {
        const response = await repository.accountInfo()
        if (response.status === 200 && response.data) {
          this.balance = Number(response.data.availablefunds.amount).toFixed(2).toString()
          this.balanceCurrency = response.data.availablefunds.currency
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: i18n.global.t('Failed to fetch market details'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async commission(payload) {
      try {
        payload.contractCurrency = this.stockCurrency ? this.stockCurrency : 'EUR'

        const response = await repository.commission(payload)
        if (response.status === 200 && response.data) {
          console.log('commission response.data', response.data)
          this.tradingFees = response.data.trading
          this.courtage = response.data.courtage
          this.totalAmount = response.data.total
          this.stockTotalAmount = response.data.amount

          this.euroTradingFees = response.data.euro_trading
          this.euroCourtage = response.data.euro_courtage
          this.euroTotalAmount = response.data.euro_total
          this.euroStockTotalAmount = response.data.euro_amount

          if (response.data.error) {
            useSnackbarStore().snack({
              text: response.data.error,
              type: 'error',
              show: true,
              autoclose: false
            })
          }
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: i18n.global.t('Failed to fetch order details'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },

    async placeOrder(payload) {
      try {
        this.setOrderStatus('loading')
        this.progress = 20

        payload.currency = this.stockCurrency

        const response = await repository.placeOrder(payload)
        this.progress = 60
        if (response.status === 200 && response.data) {
          this.setOrderStatus('success')
          this.progress = 100
        } else {
          this.setOrderStatus('failed')
        }
      } catch (e) {
        console.error(e)
        this.setOrderStatus('failed')
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async confirmOrder(payload) {
      try {
        this.loading = true

        const response = await repository.placeOrder(payload)
        if (response.status === 200 && response.data) {
          this.setOrderStatus('success')
          console.log(response.data)
          //return response.data
        }
      } catch (e) {
        this.setOrderStatus('failed')
        useSnackbarStore().snack({
          text: i18n.global.t('failed to place order'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async fetchTransactionsCount() {
      try {
        const response = await repository.fetchTransactionsCount()
        if (response.status === 200 && response.data) {
          this.noOftrades = response.data.count
        }
      } catch (e) {
        useSnackbarStore().snack({
          text: i18n.global.t('failed to fetch transaction count'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async fetchTransactions(payload, isOrder = false) {
      try {
        if (isOrder) {
          this.transactionsOrderLoading = true
        } else {
          this.transactionsLoading = true
        }
        payload.currencyLabel = this.selectedCurrency
        payload.order = 'DESC'
        const response = await repository.fetchTransactions(payload)
        if (response.status === 200 && response.data) {
          this.transactions = response.data.data
          if (response.data.data.length > 0) {
            for (let i = 0; i < response.data.data.length; i++) {
              const transaction = response.data.data[i]

              this.fetchTransaction(transaction)
            }
          }

          this.nextTransactionPageUrl = response.data.next_page_url
        }
        if (isOrder) {
          this.transactionsOrderLoading = false
        } else {
          this.transactionsLoading = false
        }
      } catch (e) {
        if (isOrder) {
          this.transactionsOrderLoading = false
        } else {
          this.transactionsLoading = false
        }

        this.setOrderStatus('failed')
        useSnackbarStore().snack({
          text: i18n.global.t('failed to fetch trades'),
          type: 'error',
          show: true,
          autoclose: false
        })
      }
    },
    async scrollTransactions(payload) {
      try {
        if (this.nextTransactionPageUrl === null) {
          this.scrollTransactionLoading = false
          this.canScrollMore = false
        } else if (this.nextTransactionPageUrl !== null && !this.loading && !this.scrollTransactionLoading) {
          this.scrollTransactionLoading = true
          const response = await repository.scrollTransactions(this.nextTransactionPageUrl, payload)
          this.scrollTransactionLoading = false

          if (response.status === 200 && response.data && response.data.data) {
            this.pushCommentData(response.data.data)
            // payload.infiniteLoader.loaded()
            this.nextTransactionPageUrl = response.data.next_page_url
          }
        }
      } catch (e) {
        // commit('SET_LOADING_NEW', false)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    pushTransactions(payload) {
      payload.find((response) => {
        const flagIndex = this.transactions.findIndex((transaction) => {
          return Number(response.id) === Number(transaction.id)
        })

        if (flagIndex === -1) {
          this.transactions.push(response)
        } else {
          this.transactions[flagIndex] = {
            ...response
          }
        }
      })

      this.transactions = [...this.transactions]
    },
    async fetchTransaction(transaction) {
      const response = await repository.fetchTransaction({ id: transaction.id, currencyLabel: this.selectedCurrency })
      if (response.status === 200 && response.data) {
        this.updateTransactions(response.data)
      }
    },
    updateTransactions(updatedTransaction) {
      if (this.transactions.length > 0) {
        const flag = this.transactions.findIndex((transaction) => {
          return Number(updatedTransaction.id) === Number(transaction.id)
        })
        this.transactions[flag] = {
          ...updatedTransaction
        }
        this.transactions = [...this.transactions]
      }
    },

    async modifyOrder(payload, selectedTransaction) {
      try {
        this.setOrderStatus('loading')
        this.progress = 20
        console.log('selectedTransaction', selectedTransaction)
        const response = await repository.modifyOrder(payload, selectedTransaction.id)
        this.progress = 60
        if (response.status === 200 && response.data) {
          this.setOrderStatus('success')
          this.progress = 100
        }
        this.fetchTransaction(selectedTransaction)
      } catch (e) {
        console.error(e)
        this.setOrderStatus('failed')
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
        this.fetchTransaction(selectedTransaction)
      }
    },
    async cancelOrder(selectedTransaction) {
      try {
        this.setOrderStatus('loading')
        const response = await repository.cancelOrder(selectedTransaction.id)
        if (response.status === 200 && response.data) {
          this.setOrderStatus('success')
        } else {
          this.setOrderStatus('failed')
        }
        this.fetchTransaction(selectedTransaction)
      } catch (e) {
        console.error(e)
        this.setOrderStatus('failed')
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
        this.fetchTransaction(selectedTransaction)
      }
    },

    setOrderStatus(status) {
      this.orderStatus = status
    },

    async accountLedger(showError = true) {
      try {
        let payload = {
          labelCurrency: this.selectedCurrency
        }

        const response = await repository.accountLedger(payload)
        //console.log('response interval', response);
        if (response.status === 200 && response.data) {
          this.portfolioValue = response.data[this.selectedCurrency].stockmarketvalue
          this.cashBalance = response.data[this.selectedCurrency].cashbalance
          this.pnl = response.data[this.selectedCurrency].unrealizedpnl
        }
      } catch (e) {
        console.error(e)
        // this.setOrderStatus('failed')
        if (showError) {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },

    async accountAllocation() {
      try {
        const response = await repository.accountAllocation()
        //console.log('response allocation', response);
        if (response.status === 200 && response.data) {
          // this.noOftrades
          // this.portfolioValue = response.data.EUR.stockmarketvalue
          // this.cashBalance = response.data.EUR.cashbalance
        }
      } catch (e) {
        console.error(e)
        // this.setOrderStatus('failed')
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async accountSummary() {
      try {
        let payload = {
          labelCurrency: this.selectedCurrency
        }
        this.accountSummaryLoading = true
        const response = await repository.accountSummary(payload)
        this.accountSummaryLoading = false

        if (response.status === 200 && response.data) {
          this.availableFunds = response.data.availablefunds.label_currency_amount.toFixed(3)
          this.availableFundsCurrency = this.selectedCurrency

          this.availableFundsEuro = response.data.availablefunds.euro_amount
        }
      } catch (e) {
        this.accountSummaryLoading = false
        console.error(e)
        // this.setOrderStatus('failed')
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },
    async accountPerformance(interval = 'D', showError = true) {
      try {
        this.accountPerformanceLoading = true
        const response = await repository.accountPerformance({
          interval: interval,
          labelCurrency: this.selectedCurrency
        })
        this.accountPerformanceLoading = false
        if (response.status === 200 && response.data) {
          this.portfolioPerformance = response.data
        }
      } catch (e) {
        this.accountPerformanceLoading = false
        console.error(e)
        // this.setOrderStatus('failed')
        if (showError) {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },

    async portfolioPosition(showError = true) {
      try {
        this.loading = true
        let payload = {
          labelCurrency: this.selectedCurrency
        }
        const response = await repository.portfolioPosition(payload)
        console.log('response.data', response.data)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.holdings = response.data
        }
      } catch (e) {
        this.loading = false
        console.error(e)
        // this.setOrderStatus('failed')
        if (showError) {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },

    async getHoldingStocks(isinId, showError = true) {
      try {
        this.loading = true
        const response = await repository.getHoldingStocks({
          isin_id: isinId
        })
        this.loading = false
        if (response.status === 200 && response.data && response.data[0] && response.data[0].position) {
          this.stocksAvailable = response.data[0].position
        }
      } catch (e) {
        this.loading = false
        console.error(e)
        // this.setOrderStatus('failed')
        if (showError) {
          if (e.response.data) {
            useSnackbarStore().snack({
              type: 'error',
              e: e.response.data,
              show: true,
              autoclose: true
            })
          } else {
            useSnackbarStore().snack({
              type: 'error',
              e: e,
              show: true,
              autoclose: true
            })
          }
        }
      }
    },

    async downloadReceipt(transaction) {
      try {
        this.receiptLoading = true
        const response = await repository.downloadReceipt(transaction.id)
        this.receiptLoading = false

        if (response.status === 200) {
          console.log('Initiated download')
          useSnackbarStore().snack({
            text: i18n.global.t('Download request is successful'),
            type: 'success',
            show: true,
            autoclose: true
          })

          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'receipt.pdf'
          link.click()
          link.remove()
        }
      } catch (e) {
        this.receiptLoading = false
        console.error(e)
        if (e.response.data) {
          useSnackbarStore().snack({
            type: 'error',
            e: e.response.data,
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },

    setStocksAvailable(stocksAvailable) {
      this.stocksAvailable = stocksAvailable
    },

    setPriceErrorCount(count) {
      this.priceErrorCount = count
    },
    setShowTradingErrorModal(boolean) {
      this.showTradingErrorModal = boolean
    },
    async fetchPrices() {
      this.stopPriceInterval()
      this.fetchPriceInterval = setInterval(async () => {
        let selectedIsin = useCompaniesStore().selectedCompany?.isins[0]?.id
        await this.fetchMarketDetails(selectedIsin)
      }, 60000)
    },
    async retryPrice() {
      this.setPriceErrorCount(0)
      this.setShowTradingErrorModal(false)

      await this.fetchPrices()
    },
    stopPriceInterval() {
      // Clear the existing interval if any
      if (this.fetchPriceInterval) {
        clearInterval(this.fetchPriceInterval)
      }

      // Clear the interval ID in the store
      this.fetchPriceInterval = null
    },
    setInitialDetailsCall(boolean) {
      this.initialDetailsCall = boolean
    },
    setInitialPriceCall(boolean) {
      this.initialPriceCall = boolean
    }
  }
})
