<template>
  <div
    class="flex items-center  pb-2 "
  >
    <div class="flex items-center px-4 pt-4 pb-2">
      <div 
        class="w-9 h-9 mr-2 bg-custom-orange-100 rounded-full flex items-center justify-center"
        :class="color === 'orange' ? 'bg-custom-orange-100' : 'bg-green-100'"
      >
        <PremiumIcon
          v-if="icon === 'premium'"
          is-premium
        />
        <Icon
          v-else
          class="text-xl"
          :class="color === 'orange' ? 'text-custom-orange-400' : 'text-custom-green-400'"
          :name="icon"
          :solid="solid"
        />
      </div>
      <span class="text-lg font-semibold  dark:text-custom-purple-100 text-custom-purple-500">
        <slot />
      </span>
    </div>
  </div>
</template>
  
  <script>
  
  export default {
    name: 'CardTitle',
    props: {
      icon: { type: String, default: '' },
      solid: { type: Boolean, default: false},
      color: { type: String, default: '' }
    },
  }
  </script>
  