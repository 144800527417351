import i18n from '@/js/language/'
import { RepositoryFactory } from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('dam')
import { defineStore } from 'pinia'
import { useSnackbarStore } from './snackbar'
import { useTradesStore } from './trades'
// import { useCompaniesStore } from "./companies"

export const useDamStore = defineStore('dam', {
  state: () => ({
    loading: false,
    ssoPath: [],
    tradingPassword: [],

    showWithdrawModal: false,
    withdrawableCash: '',
    withdrawableCurrency: '',
    instructionSetList: [],

    loadingDeposit: false,
    showDepositModal: false,
    walletTransactionsLoading: false,
    walletTransactions: [],
    wireInstructions: [],
    loadingWireInstructions: false,
    showCancelWalletTransactionModal: false,
    cancelReason: '',
    receiptLoading: false
  }),
  getters: {},
  actions: {
    setShowWithdrawModal(boolean) {
      this.showWithdrawModal = boolean
    },
    setShowDepositModal(boolean) {
      this.showDepositModal = boolean
    },
    setShowCancelWalletTransactionModal(boolean) {
      this.showCancelWalletTransactionModal = boolean
    },

    setLoading(boolean) {
      this.loading = boolean
    },

    requestSession() {
      let iprequest = new XMLHttpRequest()
      iprequest.onload = this.initSsoCreate
      iprequest.onerror = (e) => {
        console.error(e)
      }
      iprequest.open('get', 'https://api.ipify.org/?format=json')
      iprequest.send()
    },

    async getWithdrawableCash() {
      try {
        this.loading = true
        const response = await repository.getWithdrawableCash()
        this.loading = false
        if (response.status === 200 && response.data) {
          if (response.data.withdrawable_amount) {
            this.withdrawableCash = response.data.withdrawable_amount
            this.withdrawableCurrency = response.data.currency
          } else {
            useSnackbarStore().snack({
              type: 'error',
              text: JSON.stringify(response.data),
              show: true,
              autoclose: true
            })
          }
        }
      } catch (e) {
        this.loading = false
        console.error(e)
        // this.setOrderStatus('failed')
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async getInstructionSetList(showError = false) {
      try {
        this.loading = true
        const response = await repository.getInstructionSetList()
        this.loading = false
        if (response.status === 200 && response.data) {
          if (response.data.code && response.data.code === 'NO_INSTRUCTION_FOUND') {
            if (showError) {
              useSnackbarStore().snack({
                type: 'warning',
                text: 'No bank instruction details have been provided yet for wire transfer, please <strong>Setup bank details</strong> first.',
                show: true,
                autoclose: true
              })
            }
          } else if (response.data.errors && response.data.errors.length > 0) {
            if (showError) {
              for (let i = 0; i < response.data.errors.length; i++) {
                useSnackbarStore().snack({
                  type: 'error',
                  text: JSON.stringify(response.data.errors[i]),
                  show: true,
                  autoclose: true
                })
              }
            } else {
              console.error(response.data.errors.join(','))
            }
          } else if (response.data.instruction_details) {
            if (response.data.instruction_details.instruction_name) {
              this.instructionSetList = [response.data.instruction_details]
            } else {
              this.instructionSetList = response.data.instruction_details
            }
          } else {
            console.log('getInstructionSetList response.data', response.data)
            throw Error('Unexpected response')
          }
        }
      } catch (e) {
        this.loading = false
        console.error(e)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async withdrawFunds(payload) {
      try {
        this.loading = true
        const response = await repository.withdrawFunds(payload)
        this.loading = false
        if (response.status === 200 && response.data) {
          if (response.data) {
            let message = ''

            if (response.data === 'REJECTED') {
              useSnackbarStore().snack({
                type: 'error',
                text: i18n.global.t('Your withdraw request is been rejected'),
                show: true,
                autoclose: true
              })
              return
            } else if (response.data === 'PENDING') {
              message = i18n.global.t('Your withdraw request is been submitted successfully')
            } else if (response.data === 'PROCESSED') {
              message = i18n.global.t('Your withdraw request is been processed successfully')
            }
            useSnackbarStore().snack({
              type: 'success',
              text: message,
              show: true,
              autoclose: true
            })
            this.getWalletHistory({
              type: 'all'
            })
            this.setShowWithdrawModal(false)
          } else if (response.data.errors && response.data.errors.length > 0) {
            for (let i = 0; i < response.data.errors.length; i++) {
              useSnackbarStore().snack({
                type: 'error',
                text: JSON.stringify(response.data.errors[i]),
                show: true,
                autoclose: true
              })
            }
          } else {
            console.log('withdrawFunds response.data', response.data)
            throw Error('Unexpected response')
          }
        }
      } catch (e) {
        console.error(e)
        this.loading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async initSsoCreate(ipResponse) {
      console.log('ip response', ipResponse)

      let ipPayload = JSON.parse(ipResponse.target.response)
      console.log('ipPayload', ipPayload)
      try {
        this.loading = true
        const response = await repository.initSsoCreate(ipPayload)
        this.loading = false
        if (response.status === 200 && response.data) {
          this.ssoPath = response.data.sso_path
          this.tradingPassword = response.data.trading_password

          window.open(this.ssoPath, '_blank').focus()

          this.setShowWithdrawModal(false)
        } else {
          console.log('initSsoCreate response.data', response.data)
          throw Error('Unexpected response')
        }
      } catch (e) {
        this.loading = false
        console.error(e)

        console.log(e.response)
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async depositFunds(payload) {
      try {
        this.loadingDeposit = true
        const response = await repository.depositFunds(payload)
        this.loadingDeposit = false
        if (response.status === 200 && response.data) {
          if (response.data) {
            let message = ''

            if (response.data === 'REJECTED') {
              useSnackbarStore().snack({
                type: 'error',
                text: i18n.global.t('Your deposit request is been rejected'),
                show: true,
                autoclose: true
              })
              return
            } else if (response.data === 'PENDING') {
              message = i18n.global.t('Your deposit request is been submitted successfully')
            } else if (response.data === 'PROCESSED') {
              message = i18n.global.t('Your deposit request is been processed successfully')
            }
            useSnackbarStore().snack({
              type: 'success',
              text: message,
              show: true,
              autoclose: true
            })
            this.getWalletHistory({ type: 'all' })
            this.setShowDepositModal(false)
          } else if (response.data.errors && response.data.errors.length > 0) {
            for (let i = 0; i < response.data.errors.length; i++) {
              useSnackbarStore().snack({
                type: 'error',
                text: JSON.stringify(response.data.errors[i]),
                show: true,
                autoclose: true
              })
            }
          } else {
            console.log('depositFunds response.data', response.data)
            throw Error(response.data)
          }
        }
      } catch (e) {
        console.error(e)
        this.loadingDeposit = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async getWalletHistory(payload) {
      try {
        this.walletTransactionsLoading = true
        payload.currencyLabel = useTradesStore().selectedCurrency
        payload.order = 'DESC'
        const response = await repository.getWalletHistory(payload)
        this.walletTransactionsLoading = false
        if (response.status === 200 && response.data) {
          this.walletTransactions = response.data.data
        } else {
          console.log('getWalletHistory response.data', response.data)
          throw Error('Unexpected response')
        }
      } catch (e) {
        this.walletTransactions = []
        this.walletTransactionsLoading = false
        console.error(e)
        // this.setOrderStatus('failed')
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async cancelWalletTransaction(payload) {
      try {
        this.walletTransactionsLoading = true
        const response = await repository.cancelWalletTransaction(payload.id, { reason: this.cancelReason })
        this.walletTransactionsLoading = false
        if (response.status === 200 && response.data) {
          if (response.data.status) {
            let message = ''

            if (response.data.status === 'REJECTED') {
              useSnackbarStore().snack({
                type: 'error',
                text: i18n.global.t(
                  'Cannot be cancelled, either the transactions is processed it has begun processing'
                ),
                show: true,
                autoclose: true
              })
              this.getWalletHistory({ type: 'all' })
              this.setShowCancelWalletTransactionModal(false)
              return
            } else if (response.data.status === 'PENDING') {
              message = i18n.global.t('Your cancel request is been submitted successfully')
            } else if (response.data.status === 'PROCESSED') {
              message = i18n.global.t('Your cancel request is been processed successfully')
            }
            useSnackbarStore().snack({
              type: 'success',
              text: message,
              show: true,
              autoclose: true
            })

            this.getWalletHistory({ type: 'all' })
            this.setShowCancelWalletTransactionModal(false)
            this.setCancelReason('')
          } else if (response.data.errors && response.data.errors.length > 0) {
            for (let i = 0; i < response.data.errors.length; i++) {
              useSnackbarStore().snack({
                type: 'error',
                text: JSON.stringify(response.data.errors[i]),
                show: true,
                autoclose: true
              })
            }
          } else {
            console.log('withdrawFunds response.data', response.data)
            throw Error('Unexpected response')
          }
        }
      } catch (e) {
        console.error(e)
        this.walletTransactionsLoading = false
        useSnackbarStore().snack({
          type: 'error',
          e: e,
          show: true,
          autoclose: true
        })
      }
    },

    async getWireInstructions() {
      try {
        this.loadingWireInstructions = true
        const response = await repository.getWireInstructions()
        this.loadingWireInstructions = false
        if (response.status === 200 && response.data) {
          this.wireInstructions = response.data
        } else {
          console.log('getWireInstructions response.data', response.data)
          throw Error('Unexpected response')
        }
      } catch (e) {
        this.wireInstructions = []
        this.loadingWireInstructions = false
        console.error(e)
        // this.setOrderStatus('failed')
      }
    },

    async downloadReceipt(transaction) {
      try {
        this.receiptLoading = true
        const response = await repository.downloadReceipt(transaction.id)
        this.receiptLoading = false

        if (response.status === 200 && response.data) {
          console.log('Initiated download')
          useSnackbarStore().snack({
            text: i18n.global.t('Download request is successful'),
            type: 'success',
            show: true,
            autoclose: true
          })

          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'receipt.pdf'
          link.click()
          link.remove()
        }
      } catch (e) {
        this.receiptLoading = false
        console.error(e)
        if (e.response.data) {
          useSnackbarStore().snack({
            type: 'error',
            e: e.response.data,
            show: true,
            autoclose: true
          })
        } else {
          useSnackbarStore().snack({
            type: 'error',
            e: e,
            show: true,
            autoclose: true
          })
        }
      }
    },

    setCancelReason(reason) {
      this.cancelReason = reason
    }
  }
})
