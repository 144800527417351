import { get, put, post, upload } from '../helpers/axiosHelper'

const url = `${process.env.VUE_APP_API_URL}/posts`

const list = async (filter) => get(`${url}`, filter)
const store = async (data) => upload(`${url}`, data)
const filter = async (data) => post(`${url}/filters`, data)
const follow = async (id) => post(`${url}/${id}/follow`)
const addComment = async (id, data) => upload(`${url}/${id}/comments`, data)
const commentList = async (id) => get(`${url}/${id}/comments`)
const triggerCommentList = async (companyId, triggerId) => get(`${process.env.VUE_APP_API_URL}/companies/${companyId}/triggers/${triggerId}/comments`)
const addTriggerComment = async (companyId, triggerId, data) => upload(`${process.env.VUE_APP_API_URL}/companies/${companyId}/triggers/${triggerId}/comments`, data)
const fetchSubComment = async (id) => get(`${process.env.VUE_APP_API_URL}/comments/${id}/comments-with-reply`)
const addSubComment = async (postId, commentId, data) => upload(`${url}/${postId}/comments/${commentId}/comments`, data)
const saveAnswer = async (poll, pollAnswer) => put(`${url}/${poll}/answers/${pollAnswer}/votes`)
const saveOption = async (id, data) => post(`${url}/${id}/answers`, data)
const postLike = async (id, data) => post(`${url}/${id}/likes`, data)
const commentLike = async (id, data) => post(`${process.env.VUE_APP_API_URL}/comments/${id}/likes`, data)
const scroll = async (link, data) => get(`${link}`, data)
const shareWithAll = async (id, data) => post(`${url}/${id}/share`, data)
const shareWithCompany = async (postId, companyId, data) => post(`${process.env.VUE_APP_API_URL}/companies/${companyId}/posts/${postId}/share`, data)
const addTriggerSubComment = async (companyId, triggerId, commentId, data) => upload(`${process.env.VUE_APP_API_URL}/companies/${companyId}/triggers/${triggerId}/comments/${commentId}/comments`, data)
const savePost = async (id) => post(`${url}/${id}/save`)
const fetchPost = async (id) => get(`${url}/${id}/fetch`)
const followers = async (id) => get(`${url}/${id}/followers`)
const fetchPinnedPost = async () => get(`${url}/pinned`)
const scrollComment = async (link) => get(`${link}`)
const reportPost = async (id) => post(`${url}/${id}/report`)
const reportComment = async (id) => post(`${process.env.VUE_APP_API_URL}/comments/${id}/report`)
const addPressReleaseSubComment = async (companyId, pressReleaseId, commentId, data) => post(`${process.env.VUE_APP_API_URL}/companies/${companyId}/press-releases/${pressReleaseId}/comments/${commentId}/comments`, data)
const pressReleaseCommentList = async (companyId, pressReleaseId) => get(`${process.env.VUE_APP_API_URL}/companies/${companyId}/press-releases/${pressReleaseId}/comments`)
const addPressReleaseComment = async (companyId, pressReleaseId, data) => post(`${process.env.VUE_APP_API_URL}/companies/${companyId}/press-releases/${pressReleaseId}/comments`, data)
const fetchComment = async (id) => get(`${process.env.VUE_APP_API_URL}/comments/${id}/fetch`)
const deletePost = async (id) => post(`${url}/${id}/delete`)
const deleteComment = async (id) => post(`${process.env.VUE_APP_API_URL}/comments/${id}/delete`)
const updateComment = async (id, data) => upload(`${process.env.VUE_APP_API_URL}/comments/${id}/update`, data)
const update = async (id, data) => upload(`${url}/${id}/update`, data)
const unpinPost = async (id) => post(`${url}/${id}/unpin`)
const sponsoredPost = async (id) => post(`${url}/${id}/sponsor`)
const newPosts = async (data) => post(`${process.env.VUE_APP_API_URL}/feeds/posts/fetch`, data)
const unsponsoredPost = async (id) => post(`${url}/${id}/unsponsor`)

export default {
  followers,
  follow,
  sponsoredPost,
  unsponsoredPost,
  list,
  store,
  filter,
  postLike,
  commentLike,
  unpinPost,
  addComment,
  commentList,
  fetchSubComment,
  addSubComment,
  saveAnswer,
  saveOption,
  scroll,
  shareWithAll,
  fetchPinnedPost,
  shareWithCompany,
  addTriggerComment,
  triggerCommentList,
  addTriggerSubComment,
  savePost,
  fetchPost,
  scrollComment,
  reportPost,
  reportComment,
  pressReleaseCommentList,
  addPressReleaseComment,
  addPressReleaseSubComment,
  fetchComment,
  deletePost,
  deleteComment,
  updateComment,
  update,
  newPosts
}
