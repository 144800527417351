<template>
  <div class="flex flex-col">
    <div class="flex flex-col">
      <div class="p-5 flex flex-col justify-start ">
        <div class="flex items-center space-x-2">
          <Avatar
            default-class="cursor-pointer w-12 h-12"
            :size="45"
            :stock-user="user.profile"
            :use-premium="false"
          />
          <h3 class="text-center cursor-pointer">
            {{ user.profile.full_name }}
          </h3>
        </div>
        <div class="flex justify-between mt-4 space-x-4">
          <RouterLink
            class="text-center cursor-pointer font-medium dark:text-custom-purple-100 text-gray-600 border border-custom-purple-400 hover:bg-gray-100  hover:dark:bg-custom-purple-450  dark:bg-custom-purple-400 w-1/2 py-2 rounded-full"
            :to="{name: 'myEucapsOverview'}"
            @click="$emit('toggle')"

          >
            <span>{{ $t('My profile') }}</span>
          </RouterLink>
          <RouterLink
            class="flex items-center justify-center cursor-pointer font-medium dark:text-custom-purple-100 text-gray-600 border border-custom-purple-400 hover:bg-gray-100 w-1/2 py-2  hover:dark:bg-custom-purple-450 dark:bg-custom-purple-400 rounded-full"
            :to="{ name: 'myFeed' }"
            @click="$emit('toggle')"
          >
            <span>{{ $t('My Feed') }}</span>
          </RouterLink>
        </div>
      </div>
      <RouterLink
        class="flex items-center font-medium border-b border-t p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450 dark:border-custom-purple-400"
        :to="{ name: 'premium' }"
        @click="$emit('toggle')"
      >
        <img
          src="/img/crown.png"
          class="premium-icon mr-2.5 h-4 w-4"
        >
        <span>Premium</span>
      </RouterLink>
      <RouterLink
        class="flex items-center font-medium border-b p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450 dark:border-custom-purple-400 relative"
        :to="{ name: 'chats' }"
        @click="$emit('toggle')"
      >
        <Bubble
          v-if="unreadMessages > 0"
          small
          background="bg-custom-orange-400"
          color="text-white"
          font-size="text-3xs"
          class="absolute rounded-full top-4 left-1 font-semibold"
        >
          {{ unreadMessages }}
        </Bubble>
        <Icon
          name="chat"
          class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
          solid
        />
        <span>Chat</span>
      </RouterLink>
      <RouterLink
        class="flex items-center font-medium border-b p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450 dark:border-custom-purple-400"
        :to="{ name: 'members' }"
        @click="$emit('toggle')"
      >
        <Icon
          name="user-circle"
          class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
          solid
        />
        <span>Members</span>
      </RouterLink>
      <RouterLink
        class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450"
        :to="{name: 'myEucapsSettings'}"
        @click="$emit('toggle')"
      >
        <Icon
          name="cog"
          class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
          solid
        />
        <span>Settings</span>
      </RouterLink>
      <RouterLink
        class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450"
        :to="{name: 'support'}"
        @click="$emit('toggle')"
      >
        <Icon
          name="help-circle"
          class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
          solid
        />
        <span>Support</span>
      </RouterLink>
      <a
        target="_blank"
        class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450"
        :href="'https://knowledge.eucaps.com/guides-demos'"
        @click="$emit('toggle')"
      >
        <Icon
          name="chalkboard"
          class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
          solid
        />
        <span>Guides & demos</span>
      </a>
      <RouterLink
        v-if="!isVerified"
        :to="{name:'onboarding'}"
        class="px-4 text-white h-14 cursor-pointer flex items-center z-30 bg-custom-orange-400 hover:bg-custom-orange-300"
      >
        <Icon
          name="id-card"
          class="mr-2 text-white text-lg"
          solid
        />
        <span class="font-medium">{{ $t('Verify your account') }}</span>
        <Icon
          name="chevron-right"
          class="ml-2 text-white text-lg"
          solid
        />
      </RouterLink>
      <RouterLink
        v-else-if="shouldSendPoi || shouldSendPoa"
        :to="{name:'myEucapsSettings'}"
        class="px-4 text-white h-14 cursor-pointer flex items-center z-30 bg-custom-orange-400 hover:bg-custom-orange-300"
      >
        <Icon
          name="id-card"
          class="mr-2 text-white text-lg"
          solid
        />
        <span class="font-medium">{{ $t('Submit Documents') }}</span>
        <Icon
          name="chevron-right"
          class="ml-2 text-white text-lg"
          solid
        />
      </RouterLink>
      <RouterLink
        v-else-if="isKycPending"
        :to="{name:'onboarding'}"
        class="px-4 text-white h-14 cursor-pointer flex items-center z-30 bg-custom-orange-400 hover:bg-custom-orange-300"
      >
        <Icon
          name="id-card"
          class="mr-2 text-white text-lg"
          solid
        />
        <span class="font-medium">{{ $t('Complete your KYC') }}</span>
        <Icon
          name="chevron-right"
          class="ml-2 text-white text-lg"
          solid
        />
      </RouterLink>
    </div>
    <div
      class="flex items-center font-medium border-b dark:border-custom-purple-400 p-4 text-custom-purple-500 dark:text-custom-purple-200 w-full hover:bg-gray-100 dark:hover:bg-custom-purple-450 cursor-pointer"   
      @click="$emit('log-out')"
    >
      <Icon
        name="log-out-circle"
        class="mr-2 text-gray-600 dark:text-custom-purple-100 text-lg"
      />
      <span>Log out</span>
    </div>

  </div>
</template>
<script>
export default {
  name: 'ProfileMenu',
  props: {
    isVerified: {
      type: Boolean,
      default: false
    },
    isKycPending:{
      type: Boolean,
      default: false
    },
    shouldSendPoi:{
      type: Boolean,
      default: false
    },
    shouldSendPoa:{
      type: Boolean,
      default: false
    },
    unreadMessages: {
      type: Number,
      default: 0
    },
    user: {
      type: Object,
      required: true
    },
    ssoLoading:{
      type: Boolean,
      default: false
    }
  },
  emits: [
    'toggle',
    'log-out',
    'submit-bank-details'
  ],
}
</script>

<style scss scoped>
.light .premium-icon {
  filter: invert(32%) sepia(10%) saturate(878%) hue-rotate(176deg) brightness(95%) contrast(88%);
}
.dark .premium-icon {
  filter: invert(98%) sepia(76%) saturate(7316%) hue-rotate(182deg) brightness(109%) contrast(98%);
}
</style>