<template>
  <div>
    <div>
      <!-- <div
      v-if="attachments.length > 0"
      class="bg-main-background dark:bg-custom-purple-500 w-full text-center px-2 pt-3"
    >
      <div
        v-for="attachment in attachments"
        :key="attachment.id && attachment.linkable.id"
      >
        <AttachmentList
          :payload="attachment"
          class="py-1"
          @remove-attachment="$emit('remove-attachment', $event)"
        />
      </div>
    </div> -->

      <AttachMediaMenuContainer
        v-if="showMediaMenu"
        :gallery-key="commentId"
        :company-attachament="companyAttachament"
        preview-in-slot
        :path="path"
        :editables="editables"
        use-event-bus
        :attachment-type="'post'"
        :post-id="postId"
        @on-media-change="$emit('on-media-change', $event)"
        @on-company-data="$emit('toggle-attachment-search')"
      />
      <div
        v-if="companyDataAttachments.length > 0 && showMediaMenu"
        class="bg-white py-2 dark:bg-custom-purple-400 max-h-48 px-2 overflow-auto"
      >
        <AttachmentList
          v-for="attachment in companyDataAttachments"
          :key="attachment.id"
          class="mb-2 mx-2"
          :payload="attachment"
          :show-company-name="true"
          @remove-attachment="$emit('remove-attachment', $event)"
        />
      </div>
      <div
        v-if="replyMode"
        class="
          px-4
          py-2
          flex
          justify-between
          border border-gray-200
          dark:border-custom-purple-500
          shadow-2xl
          dark:shadow-none
          bg-white
          dark:bg-custom-purple-400
        "
      >
        <span class="inline-block text-sm align-middle pt-2">{{ $t('Reply to') }}: {{ selectedComment.stock_user.username }}</span>
        <Icon
          class="text-2xl font-semibold text-custom-orange-400 dark:text-custom-purple-100"
          name="x"
          @click="$emit('clear')"
        />
      </div>

      <div
        class="flex items-center border-t dark:border-custom-purple-300"
        :class="hasPadding ? '-mx-4 pl-2 pr-3 pt-4' : 'px-2 py-4'"
      >
        <div class="">
          <AvatarSlot
            :size="30"
            :stock-user="user.profile"
            :use-premium="false"
            :margins="displayRealTextarea ? 'mb-5' : null"
          />
        </div>
        <div
          class="flex w-full justify-items-end"
          :class="!displayRealTextarea ? 'items-center' : ''"
        >
          <Icon
            v-show="modelValue === '' && showAttachmentIcon && !showMediaMenu"
            class="text-2xl pr-2 font-semibold text-custom-purple-500 dark:text-custom-purple-100"
            :class="!displayRealTextarea ? '' : 'mb-4'"
            name="plus"
            @click="$emit('toggle')"
          />
          <Icon
            v-show="modelValue === '' && showAttachmentIcon && showMediaMenu"
            class="text-2xl pr-2 font-semibold text-custom-orange-400 dark:text-custom-purple-100"
            :class="!displayRealTextarea ? '' : 'mb-4'"
            name="x"
            @click="$emit('toggle')"
          />
          <div
            v-if="!displayRealTextarea"
            class="relative border border-gray-300 dark:border-custom-purple-300 rounded-md dark:text-custom-purple-100 dark:bg-custom-purple-450 w-full px-2 py-2 h-9"
            :class="modelValue ? 'bg-custom-yellow-300 bg-opacity-50' : ''"
            @click="onDisplayRealTextarea"
          >
            <div v-html="modelValue ? modelValue : 'Write comment here'" />
            <div
              v-if="modelValue !== ''"
              class="z-20 absolute inset-y-0 right-0 cursor-pointer flex items-center"
              @click.stop="$emit('update:model-value', '')"
            >
              <Icon
                name="x"
                class="text-xl px-4 py-2"
              />
            </div>
          </div>
          <QuillEditor
            v-show="displayRealTextarea"
            :element-id="'quillEditor' + postId"
            :post-id="postId"
            post-comment
            :content="modelValue"
            :emoji="emoji"
            :placeholder="$t('Write comment here')"
            :max-length="500"
            :edit-mode="editMode"
            class="w-full"
            :max-height="'small'"
            with-send-button
            @submit="$emit('submit', $event)"
            @clear="$emit('clear')"
            @update:model-value="$emit('update:model-value', $event)"
            @emoji="$emit('emoji')"
            @blur="onDisplayRealTextarea"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Quill } from 'vue3-editor'
import eventBus from '@/js/eventbus'

import AttachMediaMenuContainer from '@/modules/attachMediaMenu'
export default {
  name: 'PostCommentArea',
  components: {
    AttachMediaMenuContainer
  },
  props: {
    modelValue: { type: String, default: '' },
    emoji: { type: Boolean, default: false },
    path: { type: String, default: 'media/comments/' },
    showMediaMenu: { type: Boolean, default: false },
    postId: { type: Number, default: null },
    editables: { type: Array, default: null },
    stockUser: { type: Object, default: () => { } },
    user: { type: Object, default: () => { } },
    showAttachmentIcon: { type: Boolean, default: true },
    commentId: { type: String, default: '' },
    companyAttachament: { type: Boolean, default: true },
    replyMode: { type: Boolean, default: false },
    selectedComment: { type: Object, default: () => { } },
    companyDataAttachments: { type: Array, default: () => [] },
    editMode: { type: Boolean, default: false },
    hasPadding: { type: Boolean, default: true }
  },
  emits: [
    'on-media-change',
    'toggle-attachment-search',
    'remove-attachment',
    'toggle',
    'submit',
    'clear',
    'update:model-value',
    'emoji'
  ],
  data () {
    return {
      displayRealTextarea: false
    }
  },
  mounted () {
    eventBus.$on('on-user-tag', (payload) => {
      if (this.postId === payload.postId && payload.type === 'post') {
        this.onTag()
      }
    })
    eventBus.$on('on-company-tag', (payload) => {
      if (this.postId === payload.postId && payload.type === 'post') {
        this.onTag()
      }
    })
  },
  methods: {
    eventEmoji (event) {
      console.log('event', event)
      this.$emit('emoji', event)
    },
    onTag () {
      this.displayRealTextarea = true
      this.focusQuill()
    },
    focusQuill () {
      setTimeout(() => {
        var container = document.querySelector('#quillEditor' + this.postId)
        var quill = Quill.find(container)
        quill.focus()
      }, 100)
    },
    onDisplayRealTextarea () {
      this.displayRealTextarea = !this.displayRealTextarea
      if (this.displayRealTextarea) {
        this.focusQuill()
      }
    }
  }
}
</script>
