<template>
  <div
    v-touch:hold="touchHold"
    :class="[background, border, shadow,  !to ? padding : 'hover:bg-gray-100 dark:hover:bg-custom-purple-450', isMobile? 'rounded-md' : 'rounded-xl',]"
    class="flex flex-col"
    @click="$emit('click')"
  >
    <component
      :is="to ? 'RouterLink' : 'div'"
      :to="to ? to : undefined"
      :class="to ? padding ? padding :'-:p-4' : 'h-full'"
    >
      <div
        v-if="$slots.top"
        :class="[headerPadding,headerMargin,headerBorder]"
        class="flex justify-between items-center"
      >
        <slot name="top" />
      </div>
      <div
        :class="body"
      >
        <slot name="body" />
      </div>
      <div
        v-if="$slots.left"
        class="flex flex-col sm:flex-row"
        :class="'justify-'+justify+' items-'+items"
      >
        <div>
          <slot name="left" />
        </div>
        <div v-if="$slots.right">
          <slot name="right" />
        </div>
      </div>
      <div
        v-if="$slots.bottom"
        class="mt-4 p-4 flex flex-col sm:flex-row justify-between items-center"
      >
        <slot name="bottom" />
      </div>
    </component>
  </div>
</template>

<script>

import { isMobile } from '@/js/helpers/mobile'

export default {
  name: 'BaseCard',
  props: {
    headerBorder: { type: String, default: '' },
    headerMargin: { type: String, default: 'mt-4' },
    padding: { type: String, default: 'p-4' },
    justify: { type: String, default: 'start' },
    to: { type: Object, default: () => { } },
    items: { type: String, default: 'start' },
    body: { type: String, default: 'flex flex-col flex-grow h-full' },
    background: { type: String, default: 'bg-white dark:bg-custom-purple-400' },
    border: { type: String, default: '' },
    shadow: { type: String, default: 'shadow-sm dark:shadow-none' },
    headerPadding: { type: String, default: 'pb-4' }
  },
  emits: [
    'click',
    'touch-hold'
  ],
  computed: {
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    // deprecated in Vue 3 hence commmented
    // slotExist (type) {
    //   return this.$slots[type] !== undefined && this.$slots[type].length > 0
    // },
    touchHold () {
      this.$emit('touch-hold')
    }
  }
}
</script>
