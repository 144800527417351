<template>
  <highcharts
  
    ref="chart"
    :constructor-type="'stockChart'"
    :options="chartOptions"
  />
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  name: 'LoaderCircle',
  components: {
    highcharts: Chart
  },
  props:{
    progress: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'gauge',
          height: '80%',
          width: '220',
          backgroundColor: 'transparent'
        },
        pane: {
          startAngle: -140,
          endAngle: 140,
          background: null,
          center: ['50%', '75%'],
          size: '110%'
        },
        // the value axis
        yAxis: {
          min: 0,
          max: 100,
          tickPosition: 'outside',
          tickColor: '#FFFFFF',
          tickInterval: 16.6,
          tickLength: 10,
          tickWidth: 7,
          lineWidth: 0,
          minorTickInterval: null,
          labels: {
            enabled: false
          },
          plotBands: [{
            from: 0,
            to: this.progress ,
            color: '#56F69B',
            thickness: 10
          },
          {
            from: this.progress ,
            to: 100,
            color: '#D3D3D3',
            thickness: 10
          }]
        },
        plotOptions: {
          series: {
            enableMouseTracking: false
          }
        },
        series: [{
          name: 'Speed',
          data: [this.progress ],
          dataLabels: {
            align: 'center',
            enabled: true,
            format: '{y}',
            style: {
              textAlign: 'center',
              fontSize: '35px'
            },
            borderWidth: 0
          },
          dial: {
            backgroundColor: 'transparent'
          },
          pivot: {
            backgroundColor: 'transparent'
          }
        }],
        legend: {
          enabled: false
        },
        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        }
      }
    }
  },
  watch:{
    progress(){
      this.chartOptions.series[0].data = [this.progress]
      this.chartOptions.yAxis.plotBands[0].to = this.progress
      this.chartOptions.yAxis.plotBands[1].from = this.progress
    }
  }
}
</script>
