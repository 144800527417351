<template>
  <div class="flex flex-col p-10 pl-20 lg:w-1/2 w-full">
    <div class="flex cursor-pointer">
      <Logo
        large
        class="cursor-pointer"
        @click="$emit('redirect-home', $event)"
      />
    </div>
    <div class="hidden lg:flex lg:flex-col lg:justify-center lg:h-full lg:pb-12">
      <UserText v-if="user==='normal'" />
    </div>
  </div>
</template>

<script>
import UserText from './UserText'

export default {
  name: 'SignupTextSection',
  components: {
    UserText
  },
  props: {
    user: {
      type: String,
      default: ''
    }
  },
emits: [
'redirect-home'
]
}
</script>
