<template>
  <ProfileMenu
    :is-verified="isVerified"
    :is-kyc-pending="isKycPending"
    :should-send-poi="shouldSendPoi"
    :should-send-poa="shouldSendPoa"
    :unread-messages="unreadMessages"
    :user="user"
    :sso-loading="damStore.loading"
    @submit-bank-details="damStore.requestSession()"
    @log-out="logout"
    @toggle="$emit('toggle', $event)"
  />
</template>
<script>
import ProfileMenu from './_components/ProfileMenu'
import { useUsersStore } from '@/stores/users'
import { useChatsStore } from '@/stores/chats'
import { useDamStore } from '@/stores/dam'
export default {
  name: 'NavProfileMenu',
  components: {
    ProfileMenu
  },
  emits: ['toggle'],
  setup() {
    const chatsStore = useChatsStore()
    const usersStore = useUsersStore()
    const damStore = useDamStore()
    return { chatsStore, usersStore,damStore }
  },
  computed: {
    allMessages() {
      return this.chatsStore.allMessages
    },
    generalChats() {
      return this.chatsStore.generalChats
    },
    user() {
      return this.usersStore.user
    },
    unreadMessages() {
      if (!this.chatsStore.unreadMessages || this.chatsStore.unreadMessages.length === 0) return 0
      let messageCount = 0
      this.chatsStore.unreadMessages.forEach(chat => {
        if (chat) {
          messageCount += chat          
        }
       })
      return messageCount
    },
    isVerified() {
      return this.user && this.user.profile && this.user.profile.is_verified
    },
    isKycPending() {
      return this.user && this.user.profile && this.user.is_kyc_pending
    },
    shouldSendPoi () {
      return this.user && this.user.should_send_poi 
    },
    shouldSendPoa () {
      return this.user && this.user.should_send_poa 
    },
  },
  methods: {
    logout() {
      this.usersStore.logout()
      this.$emit('toggle')
    }
  }
}
</script>
